import React from 'react'
import { useState, useEffect } from 'react';
import Cargando from './UIGeneral/Cargando';
import GlobalFunctions from '../services/GlobalFunctions'
import FetchService from '../services/FetchService'
import PojoProducto from '../services/PojoProductos'
import SuggestedProducts from './UIGeneral/SuggestedProducts'

const MisCompras = (params) => {
    const fetchService= new FetchService()
    const glob= new GlobalFunctions()
    const [datosCliente, setDatosCliente]= useState([])
    const [listaCompras, setListaCompras]= useState([])
    const [comprasPorEntregar, setComprasPorEntregar]= useState([])
    const [productBuyAgain, setProductBuyAgain]= useState('')
    const [productoSugeridos, setProductosSugeridos]= useState([])
    const [displayVacio, setDisplayVacio]= useState('none')
    const [displayLoading, setDisplayLoading]= useState('')
    const [cargar, setCargar]= useState(true)

    useEffect(()=>{
           if(datosCliente.length==0){
                fetchService.getDatosCliente().then(datos=>setDatosCliente(datos))
           } 
    },[datosCliente])

    useEffect(()=>{
        if(cargar && datosCliente.length>0){
          fetchListaCompras()
        }
    },[datosCliente, listaCompras, cargar])

    function fetchListaCompras(){
            const url = glob.URL_SERV+'get_compras.php?modo=getCompras&cedula='+datosCliente[0].cedula
            fetch(url)
            .then((response) => {
              return response.json()
            }).then((json) => {
                setCargar(false)
               if(json[0].id=='No hay registros'){
                    setDisplayVacio('inline')
                    setDisplayLoading('none')
                    functionSetProductosSugeridos()
               }else{
                    const temp0 = json.filter((art)=>art.estado === 'Entregada');
                    setListaCompras(temp0)
                    const temp = json.filter((art)=>art.estado !== 'Entregada');
                    setComprasPorEntregar(temp)
               } 
            })  
    }

    function numeroAleatorio(){
        return parseInt(Math.random() * (params.productos.length - 0) + 0)
    }
   
    function functionSetProductosSugeridos(){
        if(productoSugeridos.length==0){
          // Creación de arrays para mostrar resumen de productos por categorias
        let array=[]
        let num1= numeroAleatorio()
        let num2=num1+12  
        if(num2>params.productos.length){
          num1=0
        } 
        for(let x=num1;x<num1+12;x++){
            let pojo = new PojoProducto(params.productos[x].nombre, params.productos[x].codigo)
            pojo.setListaImagenes(params.productos[x].listaImagenes)
            pojo.setImagen(pojo.listaImagenes[0].nombre)
            // darle formato al precio
            let precio_format=new Intl.NumberFormat("de-DE").format(params.productos[x].precio)
            pojo.setPrecio("$ "+precio_format)
            pojo.setRef(params.productos[x].referencia)
            array.push(pojo)
        }
          let array1=array.sort(() => Math.random() - 0.5)
          let array2=[]
          let nums=0
          if(array1.length<=9){
              nums=array1.length
          }else{
            nums=9
          }
          for(let r=0;r<nums;r++){
            array2.push(array1[r])
          }
          setProductosSugeridos(array2)
        }
      }

    function check_estado_compra(estado){
      const arraylist=[]  
        if(estado=="Recibida"){
            arraylist[0]=new PojoProducto('./Imagenes_config/check_box_verde.jpg', 'green')
            arraylist[1]=new PojoProducto('./Imagenes_config/unchecked_checkbox.png', '#c0c0c0')
            arraylist[2]=new PojoProducto('./Imagenes_config/unchecked_checkbox.png', '#c0c0c0')
            arraylist[3]=new PojoProducto('./Imagenes_config/unchecked_checkbox.png', '#c0c0c0')
        }
        if(estado=="Preparando"){
            arraylist[0]=new PojoProducto('./Imagenes_config/check_box_verde.jpg', 'green')
            arraylist[1]=new PojoProducto('./Imagenes_config/check_box_verde.jpg', 'green')
            arraylist[2]=new PojoProducto('./Imagenes_config/unchecked_checkbox.png', '#c0c0c0')
            arraylist[3]=new PojoProducto('./Imagenes_config/unchecked_checkbox.png', '#c0c0c0')
        }
        if(estado=="En camino"){
            arraylist[0]=new PojoProducto('./Imagenes_config/check_box_verde.jpg', 'green')
            arraylist[1]=new PojoProducto('./Imagenes_config/check_box_verde.jpg', 'green')
            arraylist[2]=new PojoProducto('./Imagenes_config/check_box_verde.jpg', 'green')
            arraylist[3]=new PojoProducto('./Imagenes_config/unchecked_checkbox.png', '#c0c0c0')
        }
        if(estado=="Entregada"){
            arraylist[0]=new PojoProducto('./Imagenes_config/check_box_verde.jpg', 'green')
            arraylist[1]=new PojoProducto('./Imagenes_config/check_box_verde.jpg', 'green')
            arraylist[2]=new PojoProducto('./Imagenes_config/check_box_verde.jpg', 'green')
            arraylist[3]=new PojoProducto('./Imagenes_config/check_box_verde.jpg', 'green')
        }
        return arraylist
    }

    function goWhats(){
        let href="https://api.whatsapp.com/send?phone=057"+params.lista[0].listaImagenes[0]+"&text=";
        window.open(href, "nuevo", "directories=no, location=no, menubar=no, scrollbars=yes, statusbar=no, tittlebar=no, width=800, height=600");
    }

    function goProduct(id){
        setProductBuyAgain(id)
        setTimeout(() => {
            document.getElementById('inputGoProduct').click()
        }, 100);
    }

    function goNovedades(){
        document.getElementById('Novedades').click()
    }

    function sendClickProducto(event){
        setProductBuyAgain(event.currentTarget.id)
        setTimeout(() => {
          document.getElementById('inputSuggestedProduct').click()
        }, 100);
      }
  
  if(listaCompras.length>0 || comprasPorEntregar.length>0){
    return (
        <div>  
          <div className="container border border-success">
              <input type='hidden' id='inputGoProduct' onClick={params.getClickProduct} value={productBuyAgain}/>
              <br/>
              <h5>Seguimiento compra</h5>
              {comprasPorEntregar.map((item, index)=>{
                  const formatTotal=new Intl.NumberFormat("de-DE").format(item.total_compra)
                  let domi='0'
                  if(isNaN(item.domicilio)){}else{
                      domi=item.domicilio
                  }
                 const array=check_estado_compra(item.estado)
                  return(
                      <div style={{textAlign: 'center'}} className='border' key={index}>
                          <h5 id="tv_titulo_compra_n" style={{textAlign: 'center', color: 'blue'}}>Compra N° {item.compra_n} del {item.fecha}</h5>
                          <h6 style={{textAlign: 'justify'}}>Lista artículos</h6>
                          {item.listaProductos.map((art, index)=>{
                             let img='producto_agotado.jpg' 
                             if(art.listaProductos[0].listaImagenes[0].nombre!=null){
                                  img='./Imagenes_productos/'+art.listaProductos[0].listaImagenes[0].nombre
                             }
                             const precio_format=new Intl.NumberFormat("de-DE").format(art.cambio)
                              return(
                                  <div key={index}>
                                       <div style={{padding: '0.5vh'}} className="row align-items-center">
                                              {/*div img*/}
                                              <div className="col-2"  >
                                                   <img className="img-fluid" style={{height:'4.5em', width:'4em'}} src={process.env.REACT_APP_URL_IMAGES+img}/> 
                                              </div>
                                              {/*div cant*/}
                                              <div className="col-1"  >
                                                   <h6>{art.domicilio}</h6>
                                              </div>
                                              {/*div titulo*/}
                                              <div className="col-6"  >
                                                  <h6 style={{fontSize:'0.8em'}}>{art.n_cuotas}</h6> 
                                              </div> 
                                              {/*div precio*/}
                                              <div className="col-3" >
                                                  <h6>${precio_format}</h6> 
                                              </div>
                                          </div>
                                  </div>
                              )
                          })}
                          <br/>
                          <div id="div_subtotal" className="row justify-content-around">
                                      <div className="col-lg-6 col-md-6 col-sm-6 col-12" >
                                          <h5 style={{textAlign: 'center'}}>Total</h5> 
                                      </div>
                                      <div className="col-lg-6 col-md-6 col-sm-6 col-12" >
                                          <h5 id="tv_subtotal" style={{textAlign: 'center', color: 'blue'}}>$ {formatTotal}</h5>
                                      </div>
                                      <div className="col-lg-6 col-md-6 col-sm-6 col-12" >
                                          <h5 style={{textAlign: 'center', color: 'gray'}}>Costo envio</h5> 
                                      </div>
                                      <div className="col-lg-6 col-md-6 col-sm-6 col-12" >
                                          <h5 id="tv_costo_envio" style={{textAlign: 'center', color: 'gray'}}>${domi}</h5>
                                      </div>
                                      <div className="col-lg-6 col-md-6 col-sm-6 col-12" >
                                          <h5 style={{textAlign: 'center', color: 'gray'}}>Forma de pago</h5> 
                                      </div>
                                      <div className="col-lg-6 col-md-6 col-sm-6 col-12" >
                                          <h5 id="tv_medio_de_pago" style={{textAlign: 'center', color: 'blue'}}>{item.medio_de_pago}</h5>
                                      </div>
                          </div>
                          <br/>
                          <div id="div_seguimiento_compra" className="row justify-content-around">
                              <div className="col-lg-6 col-md-6 col-sm-6 col-12" >
                                  <div className="row justify-content-center">
                                      <div className="col-4">
                                          <img src={process.env.REACT_APP_URL_IMAGES+array[0].nombre} style={{height:'1.4em', width:'1.4em'}} />
                                      </div>
                                      <div className="col-8">
                                          <h6 style={{color: array[0].codigo, textAlign: 'center'}}>Orden de compra recibida</h6> 
                                      </div>  
                                  </div>
                                  
                                  <div className="row justify-content-center">
                                      <div className="col-4">
                                          <img src={process.env.REACT_APP_URL_IMAGES+array[1].nombre} style={{height:'1.4em', width:'1.4em'}} />
                                      </div>
                                      <div className="col-8">
                                          <h6 style={{color: array[1].codigo, textAlign: 'center'}}>Preparando tus productos</h6> 
                                      </div>  
                                  </div>
                                  
                                  <div className="row justify-content-center">
                                      <div className="col-4">
                                          <img src={process.env.REACT_APP_URL_IMAGES+array[2].nombre} style={{height:'1.4em', width:'1.4em'}} />
                                      </div>
                                      <div className="col-8">
                                          <h6 style={{color: array[2].codigo, textAlign: 'center'}}>Tu compra va en camino a casa</h6> 
                                      </div>  
                                  </div>
                                  
                                  <div className="row justify-content-center">
                                      <div className="col-4">
                                          <img src={process.env.REACT_APP_URL_IMAGES+array[3].nombre} style={{height:'1.4em', width:'1.4em'}} />
                                      </div>
                                      <div className="col-8">
                                          <h6 style={{color: array[3].codigo, textAlign: 'center'}}>Compra entregada</h6> 
                                      </div>  
                                  </div>
                                  <br/>
                              </div>
                          </div>
                      </div>
                  )
              })}
          </div>
          <br/>
          <div className="container">
              <h5 style={{textAlign: 'center'}} >{listaCompras.length>0 ? 'Historial de compras': ''}</h5>
              {listaCompras.map((item, index)=>{
                  let img=''
                  let disableBtn=false
                  if(item.listaProductos[0].listaProductos.length>0){
                      img='./Imagenes_productos/'+item.listaProductos[0].listaProductos[0].listaImagenes[0].nombre
                  }else{
                      disableBtn=true
                      img='./Imagenes_productos/producto_agotado.jpg'
                  }
                  const formatPrecio=new Intl.NumberFormat("de-DE").format(item.listaProductos[0].cambio)
                  return(
                      <div style={{textAlign: 'center'}} key={index}>
                          <div style={{padding: '0.6vh'}} className="row align-items-center border">
                                              {/*div fecha*/}
                                              <div className="col-lg-2 col-md-2 col-sm-12 col-12"  >
                                                  <h6>Entregada el {item.fecha}</h6> 
                                              </div>
                                              {/*div cant*/}
                                              <div className="col-lg-2 col-md-2  col-sm-12  col-12"  >
                                                  <img src={process.env.REACT_APP_URL_IMAGES+img} className="img-fluid" style={{height:'4.5em', width:'4em'}} />
                                              </div>
                                              {/*div titulo*/}
                                              <div className=" col-lg-4 col-md-4  col-sm-12 col-12"  >
                                                  <h5 style={{fontSize:'0.8em'}}>{item.listaProductos[0].n_cuotas}</h5>
                                                  <h6 style={{fontSize:'0.8em'}}>Cant: {item.listaProductos[0].domicilio}</h6>
                                                  <h6 style={{fontSize:'0.8em'}}>c/u: ${formatPrecio}</h6> 
                                              </div> 
                                              {/*div precio*/}
                                              <div className="col-lg-4 col-md-4  col-sm-12  col-12" >
                                                  <button onClick={() => goProduct(item.listaProductos[0].fecha)} type='button' style={{width: '10em', height: '2.5em', backgroundColor: disableBtn ? 'gray' : 'green'}} disabled={disableBtn ? true : false} className={disableBtn ? 'btn btn-secondary': 'btn btn-success'}>{disableBtn ? 'No disponible' : 'Volver a comprar'}</button>
                                                  <button type='button' style={{width: '10em', height: '2.5em', backgroundColor: 'white', color: 'green', margin: '0.4em'}}  className={'btn btn-success'}>Ver detalles</button>
                                              </div>
                                          </div>
                      </div>
                  )
              })}
              <br/>
          </div>
          <div onClick={goWhats} style={{textAlign: 'center'}} className="container" >
              <div className="row justify-content-center">
                  <div className="col-8">
                      <a className="btn btn-primary">Inquietudes? Escribenos!</a>	 
                  </div>                
                  <div className="col-4">
                      <img style={{width: '2em', cursor: 'pointer'}} src={process.env.REACT_APP_URL_IMAGES+'/Imagenes_config/whatsapp1.png'}/>
                  </div>  
              </div>                 
          </div>
          <br/>
        </div>
        )
  }else{
    return(
        <div style={{textAlign: 'center', marginTop: '4em'}}>
            <div style={{display: displayLoading}} >
                <Cargando />
            </div>
            <div style={{display: displayVacio, padding: '1em'}}>
                <h5>Aún no haz realizado compras!</h5>
                <input type='hidden' id='Novedades' onClick={params.searchNovedades}/>
                <button type="button" onClick={goNovedades} className="btn btn-outline-success">Presiona aquí para ver muchas super novedades!!!</button>
            </div>
            <br/> <br/> <br/> <br/>
            <input type='hidden' id='inputSuggestedProduct' onClick={params.getClickProduct} value={productBuyAgain}/>
            <div style={{display: displayVacio}}>
                <SuggestedProducts clickSuggested={sendClickProducto} categoria='Prueba estos productos en tu hogar!' productos={productoSugeridos}/>
            </div>
        </div>
        
    )
  } 
  
}

export default MisCompras