import React from 'react'
import CopyRight from './UIGeneral/CopyRight';

const Contact = (params) => {
  let linkFb, dir, correo;
  let telefonos=[]
  let datosDir=[]

params.datos.map(item => {
    dir=item.categoria
    linkFb=item.descripcion
    telefonos = item.listaImagenes
    correo=item.codigo
})

datosDir=dir.split('.');


function goFb(){
	window.open(linkFb, "nuevo", "directories=no, location=no, menubar=no, scrollbars=yes, statusbar=no, tittlebar=no, width=800, height=600");
}

function goWhats(){
  let href="https://api.whatsapp.com/send?phone=57"+telefonos[0]+"&text=Hola. Estoy en su web y tengo la siguiente pregunta!";
  window.open(href, "nuevo", "directories=no, location=no, menubar=no, scrollbars=yes, statusbar=no, tittlebar=no, width=800, height=600");
}

  return (  
<footer style={{backgroundColor:'#f9f9c5'}} className="page-footer font-small indigo">
  <div className="container">
    <div className="row text-center d-flex justify-content-center pt-5 mb-3">
      <div className="col-md-2 mb-3">
        <h6 className="text-uppercase font-weight-bold">
          <a href="#!">Nosotros</a>
        </h6>
      </div>
    </div>
    <div className="row d-flex text-center justify-content-center mb-md-0 mb-4">
      <div className="col-md-8 col-12 mt-5">
        <div className="row">
          <div className="col-md-6">
            <img width="200px;" src={process.env.REACT_APP_URL_IMAGES+'/Imagenes_config/casa_bonita_google_play.png'}/>
          </div>
          <div className="col-md-6">	
                {datosDir.map((item, index)=>{
                  return(
                    <p style={{margin:'2px'}} key={index}>
                    {item}
                    </p>
                  )
                })}
                <br/>
                <span>Télefonos: </span>
              {telefonos.map((item, index)=>{
                  return(
                    <span style={{margin:'2px'}} key={index}>
                    {item +'-'}
                    </span>
                  )
                })}
              <br/><br/>
              {correo}
              <br/><br/>
              <div className="container"> 
                <div className="row justify-content-center">
                  <div className="col-6">
                  <a onClick={goWhats} className="btn btn-primary">Escribenos!</a>	 
                  </div>
                  <div className="col-6">
                  <a onClick={goWhats} className='cursorPointer'><img src={process.env.REACT_APP_URL_IMAGES+'/Imagenes_config/whatsapp1.png'}/></a> 
                  </div>  
                </div>  
              </div>
          </div>	
        </div>
      </div>
    </div>
	<br/>
    <div className="row pb-3">
      <div className="col-md-12 textAlignCenter">
        <div className="mb-5 flex-center">
         {/*Facebook*/}
          <a onClick={goFb} style={{margin:'5px'}} className="fb-ic cursorPointer">
            <i  className="fab fa-facebook-f fa-lg white-text mr-4"> </i>
          </a>
          
          {/*Google
          <a className="gplus-ic">
            <i className="fab fa-google-plus-g fa-lg white-text mr-4"> </i>
          </a>
          */}
          {/*Instagram*/}
          <a style={{margin:'5px'}} className="ins-ic">
            <i className="fab fa-instagram fa-lg white-text mr-4"> </i>
          </a>
        </div>
      </div>
    </div>
  </div>
  <CopyRight version={params.datos[0].otros} />
</footer>
  )
}

export default Contact