import React from 'react'

const SuggestedProducts = (params) => {

  return (
    <div >
      <div className="tituloCategorias rounded">
        <h5 style={{ fontSize: '1.4em', padding: '0.5em' }} className='textAlignCenter'>{params.categoria}</h5>
      </div>
      <div >
        <div style={{ margin: '0.2em' }} className="row ">
          {params.productos.map((item, index) => {
            return (
              <div style={{ marginTop: '0.4em' }} key={index} id={item.codigo} onClick={params.clickSuggested} className="card col-md-4 col-sm-12 col-12 card-flyer rounded cursorPointer">
                  <img style={{ marginTop: '0.4em', width: window.screen.width > 600 ? '60%' : '80%', height: 'auto' }} src={process.env.REACT_APP_URL_IMAGES + '/Imagenes_productos/' + item.imagen} className="card-img-top rounded centerImgCarousel" />
                  <h5 style={{ marginTop: '0.2em' }} className='textAlignCenter generalFontStyle' >{item.nombre}</h5>
                  <h3 style={{ color: item.precio == '$ 0' ? 'gray' : 'black' }} className="fontSizePreciosSuggested">
                    {item.precio == '$ 0' ? 'Agotado' : item.precio}
                  </h3>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default SuggestedProducts