import React, { useState, useEffect } from 'react'
import Cargando from './UIGeneral/Cargando';
import Carousel from 'react-bootstrap/Carousel'
import SuggestedProducts from './UIGeneral/SuggestedProducts'
import PojoProducto from '../services/PojoProductos'
import Contact from './Contact';

const Home = (params) => {
  const [promos, setPromos] = useState([])
  // establecer id recibido del onclick de suggestedProduct y que sera enviado a home.js
  const [idProducto, setIdProducto] = useState('')
  let closeBtn
  checkCloseBtn()
  // cargar datos del servidor
  let urlPromo = params.url + "get_categorias.php?code=promo"
  useEffect(() => {
    fetch(urlPromo).then((response) => {
      return response.json()
    })
      .then((json) => {
        setPromos(json)
      })
  }, [])

  function checkCloseBtn() {
    var get = sessionStorage.getItem('closeBtns');
    if (get == null) {
      get = 0
    }
    closeBtn = get
  }

  function closeWhats() {
    document.getElementById("divWhats").style.display = "none"
    document.getElementById("divFb").style.display = "none"
    closeBtn++
    sessionStorage.setItem('closeBtns', closeBtn)
  }

  function goFb() {
    window.open(params.linkFb, "nuevo", "directories=no, location=no, menubar=no, scrollbars=yes, statusbar=no, tittlebar=no, width=800, height=600");
  }

  function goWhats() {
    let href = "https://api.whatsapp.com/send?phone=57" + params.tel + "&text=Hola. Estoy en su web y tengo la siguiente pregunta!";
    window.open(href, "nuevo", "directories=no, location=no, menubar=no, scrollbars=yes, statusbar=no, tittlebar=no, width=800, height=600");
  }

  if (promos.length > 0) {
    // cerrar botones automaticamente
    if (closeBtn >= 4) {
      setTimeout(closeB, 4000)
      function closeB() {
        let btn = document.getElementById('divWhats')
        if (btn != null) {
          document.getElementById('divWhats').style.display = 'none'
          document.getElementById("divFb").style.display = "none"
        }
      }
    }
    // Creación de arrays para mostrar resumen de productos por categorias
    let matrix = []
    let countCates = params.categorias.length
    for (let i = 0; i < countCates; i++) {
      let array = []
      for (let x = 0; x < params.productos.length; x++) {
        if (params.categorias[i].nombre == params.productos[x].categoria) {
          let pojo = new PojoProducto(params.productos[x].nombre, params.productos[x].codigo)
          pojo.setDescripcion(params.productos[x].descripcion)
          pojo.setListaImagenes(params.productos[x].listaImagenes)
          pojo.setImagen(pojo.listaImagenes[0].nombre)
          // darle formato al precio
          let precio_format = new Intl.NumberFormat("de-DE").format(params.productos[x].precio)
          pojo.setPrecio("$ " + precio_format)
          pojo.setRef(params.productos[x].referencia)
          array.push(pojo)
        }
      }
      let array1 = array.sort(() => Math.random() - 0.5)
      let array2 = []
      let nums = 0
      if (array1.length <= 12) {
        nums = array1.length
      } else {
        nums = 12
      }
      for (let r = 0; r < nums; r++) {
        array2.push(array1[r])
      }
      matrix[i] = array2
    }

    function sendClickProducto(event) {
      let id = event.currentTarget.id;
      setIdProducto(id)
      // darle un tiempo para renderizar el input con el valor id
      setTimeout(goAppjs, 100)
      function goAppjs() {
        let div = document.getElementById('inputIdProduct');
        div.click()
      }
    }

    function loadingImgCarousel() {
      document.getElementById('spanCargandoCarousel').style.display = 'none'
    }

    function loadingImgCates() {
      document.getElementById('spanCargandoCates').style.display = 'none'
    }

    // Inicio class jsx
    return (
      <div>
        <div style={{ marginTop: 3 }}>
          <span id='spanCargandoCarousel' className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <Carousel variant="dark" indicators={false}>
            {
              promos.map((item, index) => {
                return (
                  <Carousel.Item className='textAlignCenter' key={index}>
                    <img onLoad={loadingImgCarousel} id={item.referencia} onClick={params.onCarousel} className={'rounded cursorPointer ' + params.thisWidth}
                      src={process.env.REACT_APP_URL_IMAGES + item.imagen}
                      alt=""
                    />
                    <h3 style={{ marginTop: '0.2em' }} className='textAlignCenter superTituloNegro'>{item.nombre}</h3>
                  </Carousel.Item>
                )
              })
            }
          </Carousel>
        </div>
        {/*Cards categorias*/}
        <br></br>
        <div>
          <h1 style={{ fontSize: '1.4em', padding: '0.5em' }} className='tituloCategorias'>Categorias</h1>
        </div>
        <div >
          <div className="row">
            <span id='spanCargandoCates' className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            {params.categorias.map((item) => {
              return (
                <div style={{ marginTop: 2 }} id={item.nombre} onClick={params.onCategory} key={item.codigo} className='col-md-4 col-sm-6 col-6 cursorPointer card-flyer rounded'>
                  <div >
                    <h1 style={{ marginTop: 6, fontSize: '1.4em' }} className="card-title generalFontStyle">
                      {item.nombre}
                    </h1>
                    <img alt={item.nombre} onLoad={loadingImgCates} className="card-img-top img-fluid" style={{ padding: 4 }} src={process.env.REACT_APP_URL_IMAGES + item.imagen} />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        {/*Botones flotantes*/}
        <div id='divFb' style={{ display: 'scroll', position: "fixed", bottom: '2%', left: '10%', zIndex: 1, cursor: 'pointer' }}>
          <a onClick={closeWhats}><i className="fas fa-window-close"></i></a>
          <h5 onClick={goFb}>Síguenos!</h5>
          <img alt="" onClick={goFb} width="50" height="50" src={process.env.REACT_APP_URL_IMAGES + '/Imagenes_config/btn_facebook.jpg'}></img>
        </div>
        <div id='divWhats' style={{ display: 'scroll', position: "fixed", bottom: '2%', right: '10%', zIndex: 1, cursor: 'pointer' }} >
          <a onClick={closeWhats} ><i className="fas fa-window-close"></i></a>
          <h5 onClick={goWhats} >Escribénos!</h5>
          <img alt='' onClick={goWhats} src={process.env.REACT_APP_URL_IMAGES + '/Imagenes_config/whatsApp_btn.png'}></img>
        </div>

        <div>
          {params.categorias.map((it, index) => {
            return (
              <div key={index}>
                <SuggestedProducts key={it.codigo} clickSuggested={sendClickProducto} categoria={it.nombre} productos={matrix[index]} />
                <a onClick={(event)=>params.verMasCategory(event, it)} className='btn btn-link'>Ver más de {it.nombre}</a>
              </div>
            )
          })}
        </div>
        <input type='hidden' name='valor' id='inputIdProduct' value={idProducto} onClick={params.getClickProduct} />
        <br />
        <Contact datos={params.datos} />
      </div>
    )
  } else {
    return (
      <Cargando />
    )
  }
}

export default Home