import React from 'react';
import './css/general.css'
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Searched from './js/Searched';
import Home from './js/Home'
import MuiAutoComplete from './js/UIGeneral/MuiAutoComplete';
import Cargando from './js/UIGeneral/Cargando';
import Product from './js/Product';
import Contact from './js/Contact';
import Login from './js/Login';
import CrearCuenta from './js/CrearCuenta';
import ShoppingCart from './js/ShoppingCart';
import GlobalFunctions from './services/GlobalFunctions'
import MyProfile from './js/UIMyprofile/MyProfile'
import CheckOut from './js/CheckOut';
import MisCompras from './js/MisCompras';

function App() {
  const glob = new GlobalFunctions()
  const [lista, setLista] = useState([])
  const [cates, setCates] = useState([])
  const [productos, setProductos] = useState([])
  const [nombreForSearch, setnombreForSearch] = useState('')
  const [idProduct, setIdProduct] = useState('')
  const [thisWidth, setthisWidth] = useState('')
  const [datosUsuario, setDatosUsuario] = useState({
    usuario: glob.getCookie('usuario'),
    correo: glob.getCookie('correo')
  })
  const [mostrarMenuPerfil, setMostrarMenuPerfil] = useState('')
  const [mensajeLogin, setMensajeLogin] = useState('')
  const [infoToPay, setInfoToPay] = useState([])
  const [colorBadgeCarrito, setColorBadgeCarrito] = useState('red')
  const [btnTogglerActive, setBtnTogglerActive] = useState(false)
  const [mensajeNoCorreo, setMensajeNoCorreo] = useState('')

  useEffect(() => {
    fetchCategorias()
    fetchProductos()
    fetchDatos()
    setthisWidth(setDimensionPantalla())
  }, [])

  useEffect(() => {
    if (idProduct !== '') {
      document.getElementById('linkProduct').click()
    }
  }, [idProduct])

  useEffect(() => {
    setTimeout(() => {
      check_session()
    }, 500);
  }, [datosUsuario])

  function setDimensionPantalla() {
    let widthDiv
    if (window.screen.width < 600) {
      widthDiv = 'imgCarouselSm'
    } else {
      widthDiv = 'imgCarouselBig'
    }
    return widthDiv
  }

  function badgeCarrito(e) {
    document.getElementById('badgeCarrito').innerHTML = e.target.value
    if (e.target.value != 0) {
      setColorBadgeCarrito('green')
    } else {
      setColorBadgeCarrito('red')
    }
  }

  function fetchDatos() {
    const urlDatos = glob.URL_SERV + "datos.php";
    fetch(urlDatos).then((response) => {
      return response.json()
    })
      .then((json) => {
        setLista(json)
        const exp = 3600 * 24 * 365
        if (json[0].otros != glob.getCookie('versionApp')) {
          glob.setCookie('versionApp', json[0].otros, exp)
          borrarCache()
        }
      })
  }

  // function para forzar cache y actualizar app
  function borrarCache() {
    if ('caches' in window) {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach(name => {
          caches.delete(name);
        })
      });
      window.location.reload(true);
    }
  }

  function fetchProductos() {
    let urlProds = glob.URL_SERV + "get_productos.php?code=all"
    fetch(urlProds)
      .then((response) => {
        return response.json()
      })
      .then((json) => {
        setProductos(json)
      })
  }

  function fetchCategorias() {
    let urlCate = glob.URL_SERV + "get_categorias.php?code=cate"
    fetch(urlCate)
      .then((response) => {
        return response.json()
      })
      .then((json) => {
        setCates(json)
      })
  }

  function goCheckOut(datos) {
    setInfoToPay(datos)
    setTimeout(() => {
      document.getElementById('checkOut').click()
    }, 500);
  }

  function check_session() {
    if (datosUsuario.correo != '' && datosUsuario.usuario != 'Ingresar') {
      setMostrarMenuPerfil('')
    } else {
      setMostrarMenuPerfil('none')
    }
  }

  function clickRegresarInicio() {
    document.getElementById('linkHome').click()
    setTimeout(() => {
      setDatosUsuario({
        usuario: glob.getCookie('usuario'),
        correo: glob.getCookie('correo')
      })
    }, 1000)
  }

  function hideBar() {
    document.getElementById('btnToggler').click()
  }

  function ir_login(event) {
    setMensajeLogin(event.target.value)
    setTimeout(() => {
      //reiniciar mensaje login despues de logearse....
      setMensajeLogin('')
    }, 5000);
    if (datosUsuario.usuario == 'Ingresar' || datosUsuario.usuario == '') {
      document.getElementById('linkLogin').click()
    } else {
      document.getElementById('myProfile').click()
    }
    if (btnTogglerActive) {
      document.getElementById('btnToggler').click()
    }
  }

  function goProfile() {
    document.getElementById('myProfile').click()
    if (datosUsuario.correo != glob.getCookie('correo')) {
      setDatosUsuario({
        usuario: glob.getCookie('usuario'),
        correo: glob.getCookie('correo')
      })
    }
  }

  function salir() {
    glob.setCookie('usuario', 'Ingresar', 0)
    glob.setCookie('correo', '', 0)
    glob.setCookie('cedula', '', 0)
    setMostrarMenuPerfil('none')
    setDatosUsuario({
      usuario: 'Ingresar',
      correo: ''
    })
    document.getElementById('linkHome').click()
    document.getElementById('badgeCarrito').innerHTML = 0
    document.getElementById('btnToggler').click()
  }

  function clickCarousel(event) {
    setIdProduct(event.target.id)
    //document.getElementById('linkProduct').click()
  }

  function clickCategory(event) {
    setnombreForSearch(event.currentTarget.id)
    activarLinkSearch()
  }

  function verMasCategory(event, item) {
    setnombreForSearch(item.nombre)
    activarLinkSearch()
  }

  function clickProduct(event) {
    if (event.target.value !== idProduct) {
      setIdProduct(event.target.value)
    } else {
      document.getElementById('linkProduct').click()
    }
    if (btnTogglerActive) {
      document.getElementById('btnToggler').click()
    }
  }

  function clickProductFromLogin() {
    setIdProduct(glob.getCookie('productForCar'))
    document.getElementById('linkProduct').click()
  }

  function goCrearCuenta(msje) {
    if (msje == 'No tienes un correo registrado!') {
      setMensajeNoCorreo(msje)
    }
    setTimeout(() => {
      setMensajeNoCorreo('')
    }, 5000);
    setTimeout(() => {
      document.getElementById('newAccount').click()
    }, 100);
  }

  function goCarrito() {
    document.getElementById('shoppingCart').click()
  }

  function functionSetnombreForSearch(cate) {
    setnombreForSearch(cate)
    document.getElementById('btnToggler').click()
    activarLinkSearch()
  }

  function activarLinkSearch() {
    document.getElementById('linkSearch').click()
  }

  function goMisCompras() {
    document.getElementById('misCompras').click()
  }

  function goHome() {
    window.location.href = glob.urlLocal;
  }

  function activarBtn() {
    if (btnTogglerActive) {
      setBtnTogglerActive(false)
    } else {
      setBtnTogglerActive(true)
    }
  }

  // comprobar que ya estes llenos para proceder a renderizar  
  if (thisWidth != '' && lista.length > 0 && cates.length > 0 && productos.length > 0) {
    let tel, nombrePagina, linkFb;

    lista.map(item => {
      tel = item.listaImagenes[0]
      nombrePagina = item.nombre
      linkFb = item.descripcion
    })
    document.getElementById('titlePage').innerHTML = nombrePagina
    // set badge carrito desde inicio
    if (glob.getCookie('badgeCarrito') != null && document.getElementById('badgeCarrito') != null) {
      document.getElementById('badgeCarrito').innerHTML = glob.getCookie('badgeCarrito')
    }
    // crear arrays para autocomplete
    let vector_productos_id = []
    let vector_productos_nombre = []
    for (let i = 0; i < productos.length; i++) {
      vector_productos_id.push(productos[i].codigo)
      vector_productos_nombre.push(productos[i].nombre)
    }

    function clickComplete(event) {
      let pal = event.target.value
      if (pal !== '') {
        let cod = null
        for (let i = 0; i < vector_productos_nombre.length; i++) {
          if (vector_productos_nombre[i] == pal) {
            cod = vector_productos_id[i]
          }
        }
        if (cod == null) {
          setnombreForSearch(pal)
        } else {
          setnombreForSearch(cod)
        }
        activarLinkSearch()
      }
      // document.getElementById('btnToggler').click()   
    }


    return (
      <Router>
        <div >
          <nav className="navbar navbar-expand-md fondoRojo">
            <div className="container">
              <button id='btnToggler' onClick={activarBtn} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#opciones">
                <span className="navbar-toggler-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
                  </svg>
                </span>
              </button>
              <Link to="/" id='linkHome' className="navbar-brand cursorPointer"  > <img alt="logo casa bonita" src={process.env.REACT_APP_URL_IMAGES + 'Imagenes_config/ico_app_foreground.png'} width="60" height="60" />
                <span className="colorBlanco">{nombrePagina}</span></Link>
              <div className="collapse navbar-collapse" id="opciones">
                <ul className="navbar-nav centrarAuto">
                  <div className="dropdown">
                    <a className="fuenteNavBar" id="dropdown1" data-toggle="dropdown">
                      <span style={{ marginLeft: '-0.5em' }} > Categorias
                        <svg style={{ marginLeft: '0.4em' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-down-square" viewBox="0 0 16 16">
                          <path fillRule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm8.5 2.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293z" />
                        </svg>
                      </span>
                    </a>
                    <div className="dropdown-menu">
                      {cates.map((cate, index) => {
                        return (
                          <a key={index} className="dropdown-item cursorPointer btn btn-light" onClick={() => functionSetnombreForSearch(cate.nombre)}>
                            {cate.nombre}
                          </a>
                        );
                      })
                      }
                    </div>
                    <Link id='linkSearch' to="/search"></Link>
                  </div>
                  <div className="dropdown-divider"></div>
                  <div className="dropdown">
                    <span className="fuenteNavBar" onClick={ir_login} style={{ marginLeft: '0.8em', textDecoration: 'underline' }}>{datosUsuario.usuario != '' ? datosUsuario.usuario : 'Ingresar'}</span>
                    <a style={{ display: mostrarMenuPerfil, marginLeft: '0.1em' }} className="fuenteNavBar" id="dropdown2" data-toggle="dropdown">
                      <svg style={{ marginLeft: '0.4em' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-down-square" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm8.5 2.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293z" />
                      </svg>
                    </a>
                    <div>
                    </div>
                    <div id="div_dropdown" className="dropdown-menu cursorPointer">
                      <Link id='myProfile' className="dropdown-item" to="/MyProfile">Mis datos</Link>
                      <Link id='misCompras' className="dropdown-item" to="/MisCompras">Mis compras</Link>
                      <a className="dropdown-item" onClick={salir}  >Salir</a>
                    </div>
                  </div>
                  <div className="dropdown-divider"></div>
                  <li className="nav-item">
                    <Link id='shoppingCart' to="/shoppingCart">
                      <button style={{ marginLeft: '0.6em' }} type="button" className="btn btn-success fuenteNavBar">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-cart-fill" viewBox="0 0 16 16">
                          <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
                        </svg>
                        <span id='badgeCarrito' style={{ backgroundColor: colorBadgeCarrito, marginLeft: '0.1em' }} className="badge badge-pill badge-light ml-1"></span>
                      </button>
                    </Link>
                  </li>
                  <div className="dropdown-divider"></div>
                  <li style={{ marginLeft: '-0.5em' }} className="nav-item">
                    <Link onClick={hideBar} className="nav-link fuenteNavBar" to="/contact">Contáctenos</Link>
                  </li>
                </ul>
                <Link id='linkProduct' to={"/product/" + idProduct}></Link>
                <Link id='linkLogin' to="/login"></Link>
                <Link id='newAccount' to="/newAccount"></Link>
                <Link id='checkOut' to="/checkOut"></Link>
              </div>
              <MuiAutoComplete className='form-control mr-sm-2' productos={productos} clickComplete={clickComplete} />
            </div>
          </nav>
          <Routes>
            <Route path="/" element={<Home url={glob.URL_SERV} thisWidth={thisWidth} onCarousel={clickCarousel} linkFb={linkFb} tel={tel} categorias={cates} productos={productos} onCategory={clickCategory} verMasCategory={verMasCategory} getClickProduct={clickProduct} datos={lista} />} />
            <Route path="/search" element={<Searched nombre={nombreForSearch} url={glob.URL_SERV} getClickProduct={clickProduct} />} />
            <Route path="/product/:id/" element={<Product thisWidth={thisWidth} productos={productos} tel={tel} datos={lista} validarCompra={goCarrito} ir_login={ir_login} />} />
            <Route path="/product" element={<Home url={glob.URL_SERV} thisWidth={thisWidth} onCarousel={clickCarousel} linkFb={linkFb} tel={tel} categorias={cates} productos={productos} onCategory={clickCategory} verMasCategory={verMasCategory} getClickProduct={clickProduct} datos={lista} />} />
            <Route path="/contact" element={<Contact datos={lista} />} />
            <Route path="/login" element={<Login clickRegresarInicio={clickRegresarInicio} clickCrearCuenta={goCrearCuenta} notificacion={mensajeLogin} goProduct={clickProductFromLogin} />} />
            <Route path="/newAccount" element={<CrearCuenta clickRegresarInicio={clickRegresarInicio} goProduct={clickProductFromLogin} mensajeNoCorreo={mensajeNoCorreo} goProfile={goProfile} />} />
            <Route path="/shoppingCart" element={<ShoppingCart productos={productos} clickRegresarInicio={clickRegresarInicio} ir_login={ir_login} searchNovedades={clickCategory} lista={lista} badgeCarrito={badgeCarrito} goProfile={goProfile} goCheckOut={goCheckOut} getClickProduct={clickProduct} />} />
            <Route path="/myProfile" element={<MyProfile lista={lista} goCarrito={goCarrito} goRegistro={goCrearCuenta} />} />
            <Route path="/checkOut" element={<CheckOut infoToPay={infoToPay} goProfile={goProfile} lista={lista} goMisCompras={goMisCompras} goCarrito={goCarrito} />} />
            <Route path="/misCompras" element={<MisCompras productos={productos} getClickProduct={clickProduct} lista={lista} searchNovedades={clickCategory} />} />
          </Routes>
        </div>
      </Router>
    )
  } else {
    return (
      <Cargando />
    )
  }
}

export default App;
