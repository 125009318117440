import React from 'react'
import { useState, useEffect } from 'react';
import FetchService from '../../services/FetchService'
import Cargando from '../UIGeneral/Cargando';
import GlobalFunctions from '../../services/GlobalFunctions'
import SelectMunicipios from './SelectMunicipios';
import DialogoAgregarTel from './DialogoAgregarTel';

const MyProfile = (params) => {
    const glob= new GlobalFunctions()
    const fetchService= new FetchService()
    const [datosCliente, setDatosCliente]= useState([])
    const [ciudades, setCiudades]= useState([])
    const [departamentos, setDepartamentos]= useState([])
    const [newDatosPersonales, setNewDatosPersonales]= useState({
        nombre: '',
        apellidos: '',
        cedula: '',
        usuario: glob.getCookie('usuario'),
        nuevoUsuario: glob.getCookie('usuario'),
        clave: '',
        correo: '',
        direccion: '',
        info_direccion: '',
        ciudad: '',
        departamento: '',
        otros: '',
        telefonos: []

    })
    const [divDatosPersonales, setDivDatosPersonales]= useState(true)
    const [divUsuario, setDivUsuario]= useState(true)
    const [displayBorrarTel, setDisplayBorrarTel]= useState('none')
    const [contraseñaIncorrecta, setContraseñaIncorrecta]= useState('')

    useEffect(()=>{
        fetDatosCliente()
        fetCiudades()
        fetDeptos()
    }, [datosCliente, divDatosPersonales,divUsuario, newDatosPersonales, ciudades])

    function fetCiudades(){
        if(ciudades.length==0){
            let url=glob.URL_SERV+"getMunicipiosDeptos.php?modo=municipios"
            fetch(url)
            .then((response) => {
                return response.json()
            }).then((json) => {
                setCiudades(json)
            }) 
        }
    }

    function fetDeptos(){
        if(departamentos.length==0){
            let url=glob.URL_SERV+"getMunicipiosDeptos.php?modo=departamentos"
            fetch(url)
            .then((response) => {
                return response.json()
            }).then((json) => {
                setDepartamentos(json)
            }) 
        }
    }

    function fetDatosCliente(){
        if(datosCliente.length==0 && glob.getCookie('correo')!=null){
           fetchService.getDatosCliente().then(datos=>setDatosCliente(datos))
        }
    }

    function validarActivarDivPersonales(){
            if(datosCliente.length>0 && divDatosPersonales==true){
                if(datosCliente[0].cedula=='' || datosCliente[0].nombre=='' || datosCliente[0].cedula==null || datosCliente[0].nombre==null ){
                     setDivDatosPersonales(false) 
                }
             }
    }

    function validarActivarDivDireccion(){
        if(datosCliente.length>0){
            if(datosCliente[0].direccion=='' || datosCliente[0].direccion==null || datosCliente[0].ciudad=='' || datosCliente[0].ciudad==null){
                setDivUsuario(false)
                setDisplayBorrarTel('inline')
           }else{
                if(divUsuario){
                    setDisplayBorrarTel('none')
                }else{
                    setDisplayBorrarTel('inline')
                }
           }
        }
    }

    function desactivar_inputs_datos_personales(){
        document.getElementById("alert_cambio").innerText="";
    } 

    function activar_inputs_datos_personales(){
        if(newDatosPersonales.nombre=='' || newDatosPersonales.cedula=='' ){
            setValorInputs()
        }
        validarActivarDivPersonales()
        validarActivarDivDireccion()
        if(divDatosPersonales==true){
            if(datosCliente[0].cedula!='' || datosCliente[0].nombre!=''){
                document.getElementById('btn_modificar_datos_personales').innerText='Escribir a asesor'
				document.getElementById('alert_cambio').innerText="Tu nombre y número de cédula se consideran datos de especial relevancia. Si deseas modificarlos puedes comunicarte con un asesor."
			}
        }else{
            document.getElementById('btn_modificar_datos_personales').innerText='Modificar'
        }	
    } 

    function activar_inputs_direccion(){
        if(newDatosPersonales.direccion=='' || newDatosPersonales.ciudad=='' || newDatosPersonales.telefonos.length==0 ){
            setValorInputs()
        }
        validarActivarDivPersonales()
        validarActivarDivDireccion()	
    }

    function setValorInputs(){
        document.getElementById('inputNombre').click()
        document.getElementById('inputApellidos').click()
        document.getElementById('inputCedula').click()
        document.getElementById('inputDireccion').click()
        document.getElementById('inputInfoDireccion').click()
        document.getElementById('inputCiudad').click()
        document.getElementById('inputDepartamento').click()
        document.getElementById('inputUsuario').click()
        document.getElementById('inputCorreo').click()
        document.getElementById('inputClave').click()
        document.getElementById('inputTelefonos').click()
    }

    function borrarTelefono(tel){
        const temp = newDatosPersonales.telefonos.filter((art)=>art !== tel);
        setNewDatosPersonales((valores) => ({
            ...valores,
            telefonos: temp
        }))
    }

    function cargarTelefonos(){
       if(newDatosPersonales.ciudad.length==0){
            setNewDatosPersonales((valores) => ({
                ...valores,
                telefonos: datosCliente[0].telefonos
            }))
       }
       //Si no hay un correo registrado redireccionar a registro de cuenta...
       if(glob.getCookie('correo')==''){
          document.getElementById('inputHiddenRedirectRegistro').click()
          return
       }
    }

    function abrirDialogoContra(){
        setContraseñaIncorrecta('')
        document.getElementById('dialogo_confirmar_password').click()
        document.getElementById('check_contraseña').value=''
    }

    function goWhats(){
        let href="https://api.whatsapp.com/send?phone=057"+params.lista[0].listaImagenes[0]+"&text=";
        window.open(href, "nuevo", "directories=no, location=no, menubar=no, scrollbars=yes, statusbar=no, tittlebar=no, width=800, height=600");
    }

    function validarFuncionBtnDatosPersonales(){
        if(divDatosPersonales){
            goWhats()
        }else{
            validarCamposVaciosPersonales()
        }
    }

    function loadingOn(){
        document.getElementById('btn_modificar_datos_personales').style.display='none'
        document.getElementById('btnLoading').style.display='inline'
        document.getElementById('btnModificarUsuario').style.display='none'
        document.getElementById('btnLoadingUsuario').style.display='inline'
    }

    function validarCamposVaciosPersonales(){
        if(newDatosPersonales.nombre=='' || newDatosPersonales.cedula==''){
            document.getElementById('alert_faltante').innerText="Falta información importante!"
        }else{
            document.getElementById('alert_faltante').innerText=""
            actualizarDatos()
        }
    }

    function actualizarDatos(){
        const url = glob.URL_SERV+'get_clientes.php?modo=actualizarCliente'
        loadingOn()
        fetch(url, {
          method: 'POST',
          body: JSON.stringify(newDatosPersonales),
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then((response) => {
          return response.json()
        }).then((json) => { 
            const exp= 3600*24*365
            glob.setCookie('usuario',  newDatosPersonales.nuevoUsuario, exp)
            glob.setCookie('correo', newDatosPersonales.correo, exp)
            recargarInterfaz()
            verificarRedireccionarCarrito()
        })
    }

    function verificarRedireccionarCarrito(){
        // si viene de dar comprar producto
        if(glob.getCookie('productForCar')!=''){
            document.getElementById('inputHiddenRedirectProduct').click()
            glob.setCookie('productForCar', '', '-1')
        }
           // si viene de dar modificar en checkout
        if(glob.getCookie('comeBackCarrito')){
            document.getElementById('inputHiddenRedirectCarrito').click()
            glob.setCookie('comeBackCarrito', '', '-1')
        }
    }

    function recargarInterfaz(){
        const array =[]
        setDatosCliente(array)
        setNewDatosPersonales((valores) => ({
            ...valores,
            nombre: '',
            apellidos: '',
            cedula: '',
            usuario: glob.getCookie('usuario'),
            nuevoUsuario: glob.getCookie('usuario'),
            clave: '',
            correo: '',
            direccion: '',
            info_direccion: '',
            ciudad: '',
            departamento: '',
            otros: '',
            telefonos: []
        }))
        setTimeout(() => {
            fetDatosCliente()
        }, 500);
    }

    function validarContraseña(){
        const inputContra=document.getElementById('check_contraseña').value;
        if(inputContra===datosCliente[0].clave){
            document.getElementById('dialogo_confirmar_password').click()
                setDivUsuario(false)
                setDisplayBorrarTel('inline')
        }else{
            setContraseñaIncorrecta('Contraseña incorrecta!')
        }
    }

    function cambioNombre(e) {
        setNewDatosPersonales((valores) => ({
          ...valores,
          nombre: e.target.value,
        }))
    }

    function cambioApellidos(e) {
        setNewDatosPersonales((valores) => ({
          ...valores,
          apellidos: e.target.value,
        }))
    }

    function cambioCedula(e) {
        setNewDatosPersonales((valores) => ({
          ...valores,
          cedula: e.target.value,
        }))
    }

    function cambioDireccion(e) {
        setNewDatosPersonales((valores) => ({
          ...valores,
          direccion: e.target.value,
        }))
    }

    function cambioInfoDireccion(e) {
        setNewDatosPersonales((valores) => ({
          ...valores,
          info_direccion: e.target.value,
        }))     
    }

    function cambioCiudad(e) {
        let codCiudad=0
        let nombreCiudad=''
        let codDepto=0
        let nombreDepto=''
        for(let i=0; i<ciudades.length;i++){  
            if(ciudades[i].codigo==e.target.value){
                codCiudad=ciudades[i].codigo;
                nombreCiudad=ciudades[i].nombre
                codDepto=ciudades[i].referencia
            }
        }
        for(let i=0; i<departamentos.length;i++){  
            if(departamentos[i].codigo==codDepto){
                nombreDepto=departamentos[i].nombre
            }
        }
        document.getElementById('inputCiudad').value=nombreCiudad
        document.getElementById('inputDepartamento').value=nombreDepto
        setNewDatosPersonales((valores) => ({
          ...valores,
          ciudad: codCiudad,
          departamento: codDepto
        }))
    }

    function setCiudad(e){
        let codCiudad=0
        let codDepto=0
        for(let i=0; i<ciudades.length;i++){  
            if(ciudades[i].nombre==e.target.value){
                codCiudad=ciudades[i].codigo;
                codDepto=ciudades[i].referencia
            }
        }
        setNewDatosPersonales((valores) => ({
          ...valores,
          ciudad: codCiudad,
          departamento: codDepto
        }))
    }

    function validarInfoDivUsuario(){  
        if(divUsuario){
          abrirDialogoContra()
        }else{ 
            if(validarDatosVaciosDir()){  
                if(newDatosPersonales.nombre=='' || newDatosPersonales.cedula==''){
                    document.getElementById('alert_faltante').innerText="Falta información importante!"
                }
            }else{
                document.getElementById('alert_faltante').innerText=""
                actualizarDatos()
            }   
        }
    }

    function validarDatosVaciosDir(){
        let vacios=true
        if(newDatosPersonales.nuevoUsuario=='' || newDatosPersonales.clave==''){
            document.getElementById('alertFaltanteUsuario').innerText="Falta información importante!"
            vacios=true
        }else{
            document.getElementById('alertFaltanteUsuario').innerText=""
            if( newDatosPersonales.direccion=='' || newDatosPersonales.ciudad=='' || newDatosPersonales.telefonos.length==0){
                document.getElementById('alertFaltanteDireccion').innerText="Falta información importante!"
                vacios=true
            }else{
                document.getElementById('alertFaltanteDireccion').innerText="" 
                vacios=false
            } 
        }
        return vacios
    }

    function cambioUsuario(e) {
        setNewDatosPersonales((valores) => ({
          ...valores,
          nuevoUsuario: e.target.value,
        }))
    }

    function cambioCorreo(e) {
        setNewDatosPersonales((valores) => ({
          ...valores,
          correo: e.target.value,
        }))
    }

    function cambioClave(e) {
        setNewDatosPersonales((valores) => ({
          ...valores,
          clave: e.target.value,
        }))
    }

    function cerrarDialogoNewTel(){
        document.getElementById('btnCerrarDialogoNewTel').click()
    }

    function agregarTelefono(e){
      cerrarDialogoNewTel() 
      let array=[]
      setNewDatosPersonales((valores) => ({
        ...valores,
        telefonos: array
     }))
      let tels = newDatosPersonales.telefonos
      tels.push(e.target.value)
      setTimeout(() => {
        setNewDatosPersonales((valores) => ({
            ...valores,
            telefonos: tels
        }))
      }, 100);
    }

  if(datosCliente.length>0 && ciudades.length>0){
    return (
        <div className="container">
            <br/>
            <input type='hidden' id='inputHiddenRedirectProduct' onClick={params.goProduct} />
            <input type='hidden' id='inputHiddenRedirectCarrito' onClick={params.goCarrito} /> 
            <input type='hidden' id='inputHiddenRedirectRegistro' onClick={()=>params.goRegistro('No tienes un correo registrado!')} />   
            <h5 id="tv_titulo" style={{color: 'black', textAlign: 'center'}}>Mis datos</h5>
            <hr/> 
            {/* datos personales*/}
            <div id="div_datos_personales" onMouseOver={activar_inputs_datos_personales} onMouseOut={desactivar_inputs_datos_personales} style={{backgroundColor: '#f4f4f4', padding: '0.4em'}}>
                <div className="row justify-content-center" >
                    <p id="alert_cambio" style={{textAlign: 'justify', color: 'brown'}}></p>
                    <p id="alert_faltante" style={{textAlign: 'justify', color: 'red'}}></p>		  
                    <div className="col-lg-4 col-sm-12" > 
                        <strong style={{fontSize: '1em'}} >Datos personales</strong>
                        <br/>		 
                        <p style={{textAlign: 'justify', color: 'black'}}>Nombres</p>
                        <input type="text"  id='inputNombre' onClick={cambioNombre} onChange={cambioNombre} readOnly={divDatosPersonales} className="form-control"  defaultValue={datosCliente[0].nombre ? datosCliente[0].nombre : ''} />
                        <br/>				
                    </div> 
                    
                    <div className="col-lg-4 col-sm-12" >
                         <br/>
                        <p style={{textAlign: 'justify', color: 'black'}}>Apellidos</p>
                        <input type="text" id='inputApellidos' onClick={cambioApellidos} onChange={cambioApellidos} readOnly={divDatosPersonales} className="form-control"  defaultValue={datosCliente[0].apellidos ? datosCliente[0].apellidos : ''}/> 
                        <br/>
                    </div> 
                </div>  
                <div className="row justify-content-center" >	
                    <div className="col-lg-4 col-sm-12" >
                        <p style={{textAlign: 'center', color: 'black'}}>Número de cédula</p>
                    </div> 
                    <div className="col-lg-4 col-sm-12" > 
                        <input type="text" id='inputCedula' onClick={cambioCedula} onChange={cambioCedula} readOnly={divDatosPersonales}  placeholder="Número de cédula" className="form-control" defaultValue={datosCliente[0].cedula ? datosCliente[0].cedula : ''} />
                    </div> 
                </div> 
                <br/>
                <div style={{textAlign: 'center'}} className="row justify-content-center">
                        <div className="col-lg-6 col-sm-6" >
                            <button onClick={validarFuncionBtnDatosPersonales} style={{backgroundColor: '#f0e094'}} id="btn_modificar_datos_personales"  className="btn btn-outline-success btn-md btn-block" type="button" >Modificar<i style={{marginLeft: '1em'}} className="fas fa-edit"></i></button>
                            <button id='btnLoading' style={{display:'none', backgroundColor:'green'}} className="btn btn-primary" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Loading...
                            </button>
                            <br/>
                        </div>
                </div>    	
            </div>  
            <br/>
            {/* Direccion de envio*/}
            <div onMouseOver={activar_inputs_direccion} style={{backgroundColor: '#f4f4f4', padding: '0.5em'}}>
                <p id="alertFaltanteDireccion" style={{textAlign: 'justify', color: 'red'}}></p>
                <div className="row justify-content-center" >
                    <div className="col-lg-8 col-sm-12">
                        <strong style={{fontSize: '1em'}} >Dirección de envio</strong>
                        <textarea  id='inputDireccion' onChange={cambioDireccion} onClick={cambioDireccion} defaultValue={datosCliente[0].direccion ? datosCliente[0].direccion : ''} readOnly={divUsuario} rows="2" className="form-control"></textarea>
                        <br/>
                    </div>
                    <div className="col-lg-8 col-sm-12">
                        <textarea readOnly={divUsuario} id="inputInfoDireccion" onChange={cambioInfoDireccion} onClick={cambioInfoDireccion} rows="2" placeholder="Información adicional: apartamento, local, torre, etc. (Opcional)" className="form-control" defaultValue={datosCliente[0].info_direccion ? datosCliente[0].info_direccion : ''}></textarea>
                        <br/>
                    </div>
                </div>

                <div style={{width: '80%'}} className='container'>
                        <SelectMunicipios mostrar={displayBorrarTel} ciudades={ciudades} className="form-control" getMunicipio={cambioCiudad} />
                </div>
                
                <div style={{textAlign: 'center'}} className="row justify-content-center" >         
                    <div className="col-lg-6 col-md-6 col-sm-6" >
                        <strong style={{fontSize: '1em'}} >Ciudad</strong>
                        <input type="text"  placeholder="Ciudad"  className="form-control" readOnly onClick={setCiudad} defaultValue={datosCliente[0].ciudad ? datosCliente[0].ciudad : ''} id="inputCiudad" /> 
                    </div>
                    <br/><br/>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                        <strong style={{fontSize: '1em'}} >Departamento</strong>
                        <input type="text"  placeholder="Departamento" className="form-control" readOnly defaultValue={datosCliente[0].departamento ? datosCliente[0].departamento : ''}   id="inputDepartamento" />
                        <br/>
                    </div>  
                </div>
               
                <p style={{textAlign: 'center', color: 'black'}}>Télefonos</p>
                    {/* div telefonos */}
                    <div style={{textAlign: 'center'}} className="container">
                                <div className="row justify-content-center" >
                                    <input type='hidden' id='inputTelefonos' onClick={cargarTelefonos} />
                                    {newDatosPersonales.telefonos.map((item, index)=>{
                                        return(       
                                            <div key={index} style={{margin: '1em'}} className="col-lg-2 col-md-2 col-sm-4 col-6 border">      
                                                <p>{item}</p> 
                                                <img  onClick={() => borrarTelefono(item)} style={{height:'0.9em', width:'0.9em', cursor: 'pointer', backgroundColor: 'red', padding: '0.1em', display: displayBorrarTel}} src={process.env.REACT_APP_URL_IMAGES+'/Imagenes_config/trash.png'}/>
                                            </div>       
                                        )       
                                    })}
                               </div>
                               <button style={{display: displayBorrarTel}} id='btnCerrarDialogoNewTel' type="button" className="btn btn-outline-primary btn-sm" data-bs-toggle="modal" data-bs-target="#dialogoNewTel"><i className="fa-solid fa-plus"></i>Nuevo télefono
                               </button> 
                               <DialogoAgregarTel cerrarDialogo={cerrarDialogoNewTel} agregarTelefono={agregarTelefono}/>      
                    </div>         
                {/* fin div telefonos */}               		
            </div>
            <br/>
             {/* Datos usuario */}  
            <div style={{backgroundColor: '#f4f4f4', padding: '0.3em'}}>
                <p id="alertFaltanteUsuario" style={{textAlign: 'justify', color: 'red'}}></p>
                <div className="row justify-content-center">
                            <div className="col-lg-8 col-sm-12" >
                                    <strong style={{fontSize: '1em'}} >Datos de cuenta</strong>
                                    <div style={{backgroundColor: '#f4f4f4', padding: '0.3em'}}>
                                        <p style={{textAlign: 'justify', color: 'black'}}>Nombre de usuario</p>
                                    <input type="text" onClick={cambioUsuario} onChange={cambioUsuario} readOnly={divUsuario} className="form-control" defaultValue={datosCliente[0].usuario ? datosCliente[0].usuario : ''}   id="inputUsuario" /> 
                                        <br/>
                                        <p  style={{textAlign: 'justify', color: 'black'}}>E-mail</p>
                                        <input type="text" onClick={cambioCorreo}  readOnly defaultValue={datosCliente[0].correo ? datosCliente[0].correo : ''} className="form-control"  id="inputCorreo" /> 
                                        <br/>
                                    </div>
                                    <strong style={{fontSize: '1em'}} >Contraseña</strong>
                                    <div style={{backgroundColor: '#f4f4f4', padding: '0.5em'}}>
                                                <input type="password"  defaultValue={datosCliente[0].clave ? datosCliente[0].clave : ''} readOnly={divUsuario} onChange={cambioClave} onClick={cambioClave} id="inputClave" className="form-control" />  
                                                <br/>
                                    </div>    
                                    <div style={{textAlign: 'center'}} className="row justify-content-center">
                                        <div className="col-lg-6 col-sm-6" >
                                            <button style={{backgroundColor: '#f0e094'}} id="btnModificarUsuario" onClick={validarInfoDivUsuario}  className="btn btn-outline-success btn-md btn-block" type="button" >Modificar<i style={{marginLeft: '1em'}} className="fas fa-edit"></i></button>
                                            <button id='btnLoadingUsuario' style={{display:'none', backgroundColor:'green'}} className="btn btn-primary" type="button" disabled>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                Loading...
                                            </button>
                                        </div>
                                    </div>
                                    <br/>
                            </div>	
                            <br/>                             
                </div>
            </div>
             {/* Dialogo confirmar contraseña */}
             <a className="nav-link" data-toggle="modal" id="dialogo_confirmar_password" data-target="#dialogo1"></a>
			 <div className="modal fade" id="dialogo1">
					  <div className="modal-dialog">
						<div className="modal-content">
							  <div className="modal-body">
								<span id="alert_check_contra" style={{color: 'black'}} >Para modificar tus datos debes ingresar tu contraseña.</span>
								<br/>
                                <p id="inputContraseñaIncorrecta" style={{textAlign: 'justify', color: 'red', fontSize: '0.9em', margin: '0.4em'}}>{contraseñaIncorrecta}</p>
								<input type="password" id="check_contraseña" className="form-control" /> 
							  </div>
						<div className="row justify-content-around">
						  <div className="col-3"> 
							<button type="button"  style={{backgroundColor: '#d22c21'}} data-dismiss="modal">Cancelar</button>
						  </div>
						  <div className="col-3">
							<button type="button" onClick={validarContraseña} style={{backgroundColor: '#228b22'}}>Continuar</button>
						  </div>
						</div> 
						<br/>	<br/>	
						</div>
					  </div>
			</div> 
					            
      </div>
      )
  }else{
    return(
        <Cargando />
    )
  }  
  
}

export default MyProfile