import { useState, useEffect } from 'react';
import PojoProducto from '../services/PojoProductos'
import Cargando from './UIGeneral/Cargando';
import Questions from './UIGeneral/Questions';
import SuggestedProducts from './UIGeneral/SuggestedProducts'
import Contact from './Contact';
import GlobalFunctions from '../services/GlobalFunctions'
import Carousel from 'react-bootstrap/Carousel'
import { useParams } from 'react-router-dom'
import { RWebShare } from 'react-web-share';

const Product = (params) => {
  const { id } = useParams()
  const glob = new GlobalFunctions()
  let pojo = ''
  const [producto, setProducto] = useState([])
  const [cargarProducto, setCargarProducto] = useState(true)
  const [cantidad, setCantidad] = useState(1)
  const [precioAntes, setPrecioAntes] = useState(true)
  const [descripcion, setDescripcion] = useState([])
  const [productoSugeridos, setProductosSugeridos] = useState([])
  const [urlProducto, setUrlProducto] = useState(glob.URL_SERV + "get_productos.php?code=" + id)
  const [loginMessage, setLoginMessage] = useState('')
  const [index, setIndex] = useState(0)

  useEffect(() => {
    if (cargarProducto) {
      setCargarProducto(false)
      fetchProduct()
    }
  }, [urlProducto])

  useEffect(() => {
    const idUrl = urlProducto.split("code=")
    if (idUrl[1] !== id) {
      setCargarProducto(true)
      setUrlProducto(glob.URL_SERV + "get_productos.php?code=" + id)
    }
  })

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
    setBordeDiv(selectedIndex)
  }

  function setBordeDiv(index) {
    var div_min = document.getElementById("div_miniaturas");
    for (var x = 0; x < div_min.childNodes.length; x++) {
      if (div_min.childNodes[x].nodeType == Node.ELEMENT_NODE) {
        div_min.childNodes[x].style.borderLeft = "";
      }
    }
    document.getElementById('divMin' + index).style.borderLeft = "thick solid brown";
  }

  function fetchProduct() {
    fetch(urlProducto)
      .then((response) => {
        return response.json()
      }).then((json) => {
        if (id === json[0].codigo) {
          setProducto(json)
          const idIconWeb = document.getElementById('idIconWeb')
          const imgIcon=process.env.REACT_APP_URL_IMAGES+'Imagenes_productos/' +json[0].listaImagenes[0].nombre
          console.log(imgIcon)
          idIconWeb.href =imgIcon
        }
      })
  }

  function sendClickProducto(event) {
    let id = event.currentTarget.id
    const urlProducto = params.datos[0].otros1 + "product/" + id
    window.location.href = urlProducto
  }

  if (producto.length > 0) {
    pojo = new PojoProducto(producto[0].nombre, producto[0].codigo)
    pojo.setDescripcion(producto[0].descripcion)
    pojo.setListaImagenes(producto[0].listaImagenes)
    // darle formato al precio
    let precio_format = new Intl.NumberFormat("de-DE").format(producto[0].precio)
    pojo.setPrecio(precio_format)
    pojo.setRef(producto[0].referencia)
    pojo.setCantidad(producto[0].cantidad)
    setTimeout(setPromoPrecio, 100)
  }

  function setPromoPrecio() {
    functionSetPrecioAntes()
    functionSetDescripcion()
  }

  function functionSetDescripcion() {
    if (descripcion.length == 0) {
      setDescripcion(pojo.descripcion.split("."))
    }
    functionSetProductosSugeridos()
  }

  function functionSetProductosSugeridos() {
    if (productoSugeridos.length == 0) {
      // Creación de arrays para mostrar resumen de productos por categorias
      let array = []
      for (let x = 0; x < params.productos.length; x++) {
        if (producto[0].categoria == params.productos[x].categoria) {
          let pojo = new PojoProducto(params.productos[x].nombre, params.productos[x].codigo)
          pojo.setListaImagenes(params.productos[x].listaImagenes)
          pojo.setImagen(pojo.listaImagenes[0].nombre)
          // darle formato al precio
          let precio_format = new Intl.NumberFormat("de-DE").format(params.productos[x].precio)
          pojo.setPrecio("$ " + precio_format)
          pojo.setRef(params.productos[x].referencia)
          array.push(pojo)
        }
      }
      let array1 = array.sort(() => Math.random() - 0.5)
      let array2 = []
      let nums = 0
      if (array1.length <= 12) {
        nums = array1.length
      } else {
        nums = 12
      }
      for (let r = 0; r < nums; r++) {
        array2.push(array1[r])
      }
      setProductosSugeridos(array2)
    }
  }

  function functionSetPrecioAntes() {
    let num = Math.random()
    let item = document.getElementById("tv_precio_antes")
    if (num > 0.8 && precioAntes && item != null && pojo.precio != '0') {
      let precio_ant = (parseInt(producto[0].precio) * 0.2) + parseInt(producto[0].precio);
      var precio_format = new Intl.NumberFormat("de-DE").format(precio_ant);
      document.getElementById("tv_precio_antes").innerText = "Antes: $ " + precio_format;
      document.getElementById("tv_precio").innerText = "Hoy: " + pojo.precio
    }
    setPrecioAntes(false)
  }

  function cambiarImagen(index) {
    setIndex(index)
    setBordeDiv(index)
  }

  function set_full_screen(id) {
    var imagen = document.getElementById('imgMain' + id)
    getFullscreen(imagen);
  }

  function getFullscreen(element) {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  }

  function menosCant() {
    if (cantidad > 1) {
      setCantidad(cantidad - 1)
    }
  }

  function masCant() {
    if (cantidad < 6) {
      setCantidad(cantidad + 1)
    }
  }

  function checkUsuario() {
    if (glob.getCookie('correo') == 'Ingresar' || glob.getCookie('correo') == null || glob.getCookie('correo') == '') {
      // guardo una cookie para cuando no hay sesion iniciada, y una vez hecha retornar al product
      glob.setCookie('productForCar', producto[0].codigo, 3600)
      setLoginMessage("Debes identificarte para agregar al carrito!")
      setTimeout(() => {
        document.getElementById('inputIrLogin').click()
      }, 100);

    } else {
      glob.setCookie('productForCar', producto[0].codigo, 0)
      guardarEnCarrito()
    }
  }

  function comprarByWhatsapp() {
    let prod = producto[0].nombre.replace('&', 'y')
    let href = "https://api.whatsapp.com/send?phone=57" + params.datos[0].listaImagenes[0] + "&text=Hola! Me interesa este producto: " + prod + ". " + params.datos[0].otros1 + "product/" + producto[0].codigo;
    window.open(href, "nuevo", "directories=no, location=no, menubar=no, scrollbars=yes, statusbar=no, tittlebar=no, width=800, height=600");
  }

  function guardarEnCarrito() {
    document.getElementById('btnComprar').style.display = 'none'
    document.getElementById('btnLoading').style.display = 'inline'
    const fecha = glob.getFecha()
    let objeto = {
      "cliente": glob.getCookie('correo'),
      "cod": producto[0].codigo,
      "producto": producto[0].nombre,
      "imagen": pojo.listaImagenes[0].nombre,
      "valor": producto[0].precio,
      "cantidad": cantidad,
      "fecha": fecha
    };
    validarInsertUpdate(objeto)
  }

  function compararItems(json, datos) {
    let cant = 0
    json.map((item) => {
      if (item.codigo == datos.cod) {
        cant = item.cantidad
      }
    })
    if (cant > 0) {
      actualizarCarrito(datos, cant)
    } else {
      insertarCarrito(datos)
    }
  }

  function actualizarCarrito(datos, cant) {
    let objeto = {
      "cod": datos.cod,
      "cliente": glob.getCookie('correo'),
      "cantidad": parseInt(cant) + 1
    };
    const url = glob.URL_SERV + 'getCarrito.php?modo=actualizarCantidad'
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(objeto),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        return response.json()
      }).then((json) => {
        validarInsercion(json)
      })
  }

  function insertarCarrito(datos) {
    const url = glob.URL_SERV + 'getCarrito.php?modo=insert';
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(datos),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(function (response) {
      return response.json();
    }).then(function (data) {
      validarInsercion(data)
    }).catch(function (error) {
      loadingOff()
    })
  }

  function validarInsertUpdate(datos) {
    const url = glob.URL_SERV + 'getCarrito.php?modo=getCarrito&id=' + glob.getCookie('correo')
    fetch(url)
      .then((response) => {
        return response.json()
      }).then((json) => {
        compararItems(json, datos)
      })
  }

  function validarInsercion(data) {
    loadingOff()
    if (data[0].nombre == "Registro") {
      document.getElementById('inputGoCarrito').click()
    } else {
      alert("Error al ingresar en carrito. Cuéntanoslo por alguno de nuestros medios de comunicación!")
    }
  }

  function loadingOff() {
    document.getElementById('btnComprar').style.display = 'inline'
    document.getElementById('btnLoading').style.display = 'none'
  }

  function loadingImgMain() {
    document.getElementById('spanCargandoImagen').style.display = 'none'
  }

  function procesarPreguntaLogin() {
    // guardo una cookie para cuando no hay sesion iniciada, y una vez hecha retornar al product
    if (glob.getCookie('correo') === '') {
      glob.setCookie('productForCar', producto[0].codigo, 3600)
    } else {
      glob.setCookie('productForCar', producto[0].codigo, 0)
    }
    setLoginMessage('Debes identificarte para preguntar!')
    setTimeout(() => {
      document.getElementById('inputIrLogin').click()
    }, 200);

  }

  if (producto.length > 0 && descripcion.length > 0) {
    // Inicio class jsx
    return (
      <div id="div_producto_todo">
        {/*div contenedor producto: miniaturas, img main y comprar*/}
        <div className="row">
          <div className="row col-lg-2 col-md-2 col-sm-12 overflow-auto" style={{ height: '30em', marginLeft: '0.2em', display: window.screen.width < 600 ? 'none' : 'inline' }} id="div_miniaturas">
            {pojo.listaImagenes.map((item, index) => {
              let img = 'Imagenes_productos/' + item.nombre;
              return (
                <div key={index} id={'divMin' + index} onClick={() => cambiarImagen(index)} className="col-sm-3 col-md-12" style={{ height: '4em', width: '7em', margin: '20px', cursor: 'pointer', marginLeft: '0.6em' }}>
                  <img className="img-fluid img-thumbnail" style={{ height: '80px', width: '100px' }} src={process.env.REACT_APP_URL_IMAGES + img} />
                  <br />
                </div>
              )
            })}
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 border" >
            <span id='spanCargandoImagen' className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <Carousel activeIndex={index} onSelect={handleSelect} variant="dark" indicators={false}>
              {
                pojo.listaImagenes.map((item, index) => {
                  let img = 'Imagenes_productos/' + item.nombre
                  return (
                    <Carousel.Item className='textAlignCenter' key={index}>
                      <img id={'imgMain' + index} onClick={() => set_full_screen(index)} onLoad={loadingImgMain} className={'cursorPointer ' + params.thisWidth}
                        src={process.env.REACT_APP_URL_IMAGES + img}
                        alt=""
                      ></img>
                    </Carousel.Item>
                  )
                })
              }
            </Carousel>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="card text-center card-flyer">
              <div>
                <br />
                <h4 id="titulo_producto" className="card-title">{pojo.nombre}</h4>
                <br />
                <h3 id="tv_precio_antes" style={{ color: 'red', textDecoration: 'line-through' }} className="card-text fontSizePreciosSuggested"></h3>
                <h3 id="tv_precio" className="card-text">$ {pojo.precio == 0 ? '-' : pojo.precio}</h3>
                <br />
                <div className="container">
                  <div className="row">
                    <div className="col-sm-5 col-12">
                      <h6 >Cantidad</h6>
                    </div>
                    <div onClick={menosCant} className="col-sm-1 col-4 cursorPointer">
                      <i className="fas fa-minus"></i>
                    </div>
                    <div className="col-sm-4 col-4">
                      <h5 >{cantidad}</h5>
                    </div>
                    <div onClick={masCant} className="col-sm-1 col-4 cursorPointer">
                      <i className="fas fa-plus"></i>
                    </div>
                  </div>
                </div>
                <br />
                <h3 className='fontSizePreciosSuggested'>Envio gratis en el área metrópolitana de Bucaramanga!</h3>
                <p >(Compras superiores a $100.000)</p>
                <br />
                <h3 className='fontSizePreciosSuggested'>A otras ciudades del pais el valor del envio es de $25.000.</h3>
                <br />
                <h3 id="tv_llega" ></h3>
                {/*formulario producto */}
                <input type='hidden' id='inputGoCarrito' onClick={params.validarCompra} />
                <input type='hidden' id='inputIrLogin' value={loginMessage} onClick={params.ir_login} />
                <button id='btnComprar' type='button' onClick={checkUsuario} style={{ backgroundColor: pojo.precio == 0 ? 'gray' : 'greeen', width: '94%' }}
                  className="btn btn-success btn-lg" disabled={pojo.precio == 0 ? true : false}>{pojo.precio == 0 ? 'Producto agotado' : 'Agregar al carrito'}
                  <i className="fa-solid fa-cart-plus fa-lg" style={{ marginLeft: '1em' }}></i>
                </button>
                <button id='btnLoading' style={{ display: 'none', backgroundColor: 'green', width: '94%' }} className="btn btn-primary btn-lg" type="button" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Loading...
                </button>
                <br></br>
                <button type='button' onClick={comprarByWhatsapp} style={{ width: '94%', marginTop: '1em', backgroundColor: pojo.precio == 0 ? 'gray' : 'greeen' }}
                  className="btn btn-success btn-lg" disabled={producto.cantidad == 0 ? true : false}>
                  Compra por
                  <i className="fa-brands fa-whatsapp fa-lg" style={{ marginLeft: '1em' }}></i>
                </button>
                <div style={{ textAlign: 'right', marginTop: '1em' }} className='container'>
                  <RWebShare
                    data={{
                      text: pojo.nombre,
                      url: params.datos[0].otros1 + "product/" + id,
                      title: pojo.nombre
                    }}
                  >
                    <button className='btn btn-outline-primary btn-sm rouded'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-share-fill" viewBox="0 0 16 16">
                        <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                      </svg>
                    </button>
                  </RWebShare>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="container">
          <div className="card text-center card-flyer">
            <h2 style={{ textAlign: 'center' }} >Descripcion</h2>
            {descripcion.map((item, index) => {
              return (
                <p key={index}>{item + ". "}</p>
              )
            })}
          </div>
          <br />
          <p><strong style={{ color: 'red' }}>*</strong>La disponibilidad, el precio y la cantidad de unidades de los productos esta sujeta a las unidades disponibles en inventario.
            Tu Casa Bonita no se hace responsable por el posible agotamiento de unidades.</p>
          <br />
        </div>
        <Questions producto={producto[0].codigo} procesarPreguntaLogin={procesarPreguntaLogin} tel={params.tel} />
        <SuggestedProducts clickSuggested={sendClickProducto} categoria='Otras personas quienes vieron este producto tambien compraron...' productos={productoSugeridos} />
        <Contact datos={params.datos} />
      </div>
    )
  } else {
    return (
      <Cargando />
    )
  }

}

export default Product