import { Autocomplete, TextField } from '@mui/material'
import React from 'react'

function MuiAutoComplete(params) {

    const [value, setValue] = React.useState('')
    const [inputValue, setInputValue] = React.useState('')

    let datos = []
    for (let i = 0; i < params.productos.length; i++) {
        let opt = new OptionsAuto(params.productos[i].codigo, params.productos[i].nombre)
        datos.push(opt)
    }

    function getInputChange(newInputValue) {
        setInputValue(newInputValue)
    }

    function getOnchange(newValue) {
        setValue(newValue)
        setTimeout(buscar, 100)
    }

    function buscar() {
        document.getElementById('inputHiddenValue').click()
        setValue('')
    }

    function submitHandler(e) {
        e.preventDefault();
    }

    return (
        <div>
            <input type="hidden" onClick={params.clickComplete} value={inputValue} id="inputHiddenValue" />
            <form onSubmit={submitHandler} id="formAutocomplete" className="form-inline">
                <div className="input-group">
                    <Autocomplete className="fondoBlanco rounded"
                        disablePortal
                        id="combo-box-demo"
                        value={value}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} InputLabelProps={{ style: { color: 'black' } }} label="Buscar..." size="small" />}
                        onChange={(_, newValue) => {
                            getOnchange(newValue)
                        }}
                        inputValue={inputValue}
                        onInputChange={(_, newInputValue) => {
                            getInputChange(newInputValue)
                        }}
                        options={datos}
                        freeSolo
                    />
                    <button className='fondoBeige rounded' type='button' onClick={buscar}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                        </svg>
                    </button>
                </div>
            </form>
        </div>
    )
}

export default MuiAutoComplete;

class OptionsAuto {
    constructor(id, label) {
        this.id = id;
        this.label = label;
    }

    getLabel() {
        return this.label
    }

    getId() {
        return this.id
    }

}
