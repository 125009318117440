import React from 'react'
import { useState, useEffect } from 'react';
import GlobalFunctions from '../services/GlobalFunctions'
import Cargando from './UIGeneral/Cargando';
import FetchService from '../services/FetchService'
import DialogoEliminar from './UIShoppingCart/DialogoEliminar';

const CheckOut = (params) => {
    const fetchService = new FetchService()
    const glob = new GlobalFunctions()
    const [datosCliente, setDatosCliente] = useState([])
    const [productos, setProductos] = useState([])
    const [totales, setTotales] = useState([])
    const [imgPago, setImgPago] = useState('')
    const [comentario, setComentario] = useState('')

    useEffect(() => {
        if (datosCliente.length == 0) {
            fetchService.getDatosCliente().then(datos => functionSetDatosCliente(datos))
        }
    }, [])

    function functionSetDatosCliente(datos) {
        setDatosCliente(datos)
        validarParamsProductos()
    }

    function validarParamsProductos() {
        if (!params.infoToPay.productos) {
            if (document.getElementById('div_pago') != null) {
                document.getElementById('div_pago').style.display = 'none'
            }
            validarEstadoPago()
        } else {
            setProductos(params.infoToPay.productos)
            setTotales(params.infoToPay.totales)
            setTimeout(() => {
                document.getElementById('validarIconoBotonCompras').click()
            }, 500);
        }
    }

    function validarEstadoPago() {
        if (glob.getCookie('refWompi') != '') {
            fetchEstadopago(glob.getCookie('refWompi'))
        } else {
            if (document.getElementById('inputGoCarrito') != null) {
                document.getElementById('inputGoCarrito').click()
            }
        }
    }

    function fetchEstadopago(ref) {
        const url = glob.URL_SERV + 'get_compras.php?modo=validarPago&ref=' + ref
        fetch(url)
            .then((response) => {
                return response.json()
            }).then((json) => {
                if (json == 'APPROVED') {
                    if (document.getElementById('inputGoMisCompras') != null) {
                        document.getElementById('inputGoMisCompras').click()
                    }
                } else {
                    if (document.getElementById('botonDialogoEliminar') != null) {
                        document.getElementById('botonDialogoEliminar').click()
                    }
                }
            })
    }

    function fetchRefWompi() {
        const url = glob.URL_SERV + 'get_compras.php?modo=refWompi&cliente=' + datosCliente[0].cedula + '&totalVenta=' + totales.totalConMedioDePago + '&fecha=' + glob.getFecha() + '&estado=ventaCasaBonitaWeb'
        fetch(url)
            .then((response) => {
                return response.json()
            }).then((json) => {
                document.getElementById('inputRefWompi').value = json[0].id
                registrarCompra(json[0].id)
                document.getElementById('formWompi').submit()
                // cookie para validar estado del pago despues de regreso de wompi
                glob.setCookie('refWompi', json[0].id, glob.setExpires('1'))
            })
    }

    // me toco hacer un input para cuando cargue (Si cargar significa que hay params) verificar la imagen a cargar para el boton pagar....
    function cargarBotonPago() {
        if (productos.length > 0) {
            if (params.infoToPay.formaPago == 'contraentrega') {
                setImgPago('Imagenes_config/ico_contraEntrega.png')
            } else {
                setImgPago('Imagenes_config/wompi_btn.png')
            }
        }
    }

    function goProfile() {
        glob.setCookie('comeBackCarrito', 'true', glob.setExpires('1'))
        document.getElementById('inputModificar').click()
    }

    function goWhats() {
        let href = "https://api.whatsapp.com/send?phone=057" + params.lista[0].listaImagenes[0] + "&text=";
        window.open(href, "nuevo", "directories=no, location=no, menubar=no, scrollbars=yes, statusbar=no, tittlebar=no, width=800, height=600");
    }

    function validarModoPago() {
        document.getElementById('btnPago').style.display = 'none'
        document.getElementById('btnPagarLoading').style.display = 'inline'
        if (params.infoToPay.formaPago == 'contraentrega') {
            registrarCompra('')
        } else {
            document.getElementById('btnPagarValidarPago').style.display = 'inline'
            iniciarTemporizador()
            fetchRefWompi()
        }
    }

    function iniciarTemporizador() {
        let i = 0
        const myInterval = setInterval(() => {
            let num = parseInt(60 - i)
            document.getElementById('spanValidarPago').innerText = "Validando pago en ... " + num
            i++
            if (i == 60) {
                if (document.getElementById('inputGoMisCompras') != null) {
                    validarEstadoPago()
                }
                clearInterval(myInterval)
            }
        }, 1000)
    }

    function registrarCompra(ref) {
        datosCliente[0].fecha = glob.getFecha()
        datosCliente[0].totales = params.infoToPay.totales
        datosCliente[0].medioPago = params.infoToPay.formaPago
        datosCliente[0].estado = "Recibida"
        datosCliente[0].vendedor = "Pagina web"
        datosCliente[0].productos = params.infoToPay.productos
        datosCliente[0].comentario = comentario
        datosCliente[0].referenciaWompi = ref
        if (datosCliente.length > 0) {
            let url = glob.URL_SERV + "get_compras.php?modo=ingresarCompra"
            fetch(url, {
                method: 'POST',
                body: JSON.stringify(datosCliente),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then((response) => {
                    return response.json()
                }).then((json) => {
                    comprobarRegistro(json)
                })
        }
    }

    function comprobarRegistro(json) {
        if (json[0].nombre == 'Registro en lista compras' && json[0].listaImagenes.length > 0) {
            if (params.infoToPay.formaPago == 'contraentrega') {
                document.getElementById('inputGoMisCompras').click()
            }
        } else {
            alert('Ha ocurrido un error! Por favor cuéntanoslo!')
        }
    }

    function changeComentario(e) {
        setComentario(e.target.value)
    }

    function processarIrcarrito() {
        document.getElementById('botonDialogoEliminar').click()
        document.getElementById('inputGoCarrito').click()
        glob.setCookie('refWompi', '', glob.setExpires('-1'))
    }

    if (datosCliente.length > 0) {
        return (
            <div className="container">
                <br />
                <input type='hidden' id='validarIconoBotonCompras' onClick={cargarBotonPago} />
                <input type='hidden' id='inputGoMisCompras' onClick={params.goMisCompras} />
                <input type='hidden' id='inputGoCarrito' onClick={params.goCarrito} />
                <div className="row justify-content-around">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12" >
                        <h5 style={{ textAlign: 'center' }}>Tu compra será entrega en:</h5>
                        <p style={{ textAlign: 'justify', color: 'black' }}>Dirección de domicilio</p>
                        <textarea name="direccion" id="direccion" readOnly rows="2" value={datosCliente[0].direccion + ". " + datosCliente[0].info_direccion + ". " + datosCliente[0].ciudad + ". " + datosCliente[0].departamento} className="form-control"></textarea>
                        <br />
                        <h5 style={{ textAlign: 'center' }}>A nombre de:</h5>
                        <input type="text" name="nombre" readOnly className="form-control" value={datosCliente[0].nombre} id="nombre" />
                        <br />
                        <p id="alert_cambio" style={{ textAlign: 'justify', color: 'black' }}>Número de cédula</p>
                        <input type="text" readOnly name="cedula" className="form-control" value={datosCliente[0].cedula} id="input_cedula" />
                        <br />
                        <h5 style={{ textAlign: 'center' }}>Y tus números telefónicos son:</h5>
                        <div style={{ textAlign: 'center' }} className="container">
                            <div className="row justify-content-center" >
                                {datosCliente[0].telefonos.map((item, index) => {
                                    return (
                                        <div key={index} className="col-4">
                                            <p>{item}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div style={{ textAlign: 'center' }} className="container">
                            <input type='hidden' id='inputModificar' onClick={params.goProfile} />
                            <button onClick={goProfile} style={{ backgroundColor: '#f0e094' }} id="btn_modificar" className="btn btn-outline-success my-2 my-sm-0" type="button" >Modificar<i style={{ marginLeft: '12m' }} className="fas fa-edit"></i></button>
                        </div>
                        <br />
                        <textarea onChange={changeComentario} placeholder="Haz nos saber si tienes algun comentario, duda o sugerencia sobre tu compra." rows="2" className="form-control"></textarea>
                        <br />

                        <h3 id="tv_set_llega" style={{ fontSize: '6em', textAlign: 'center' }}></h3>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <h5>Resumen compra:</h5>
                        {productos.map((item, index) => {
                            const img = './Imagenes_productos/' + item.imagen;
                            const precio = parseInt(item.precio) * item.cantidad
                            let precio_format = new Intl.NumberFormat("de-DE").format(precio)
                            return (
                                <div key={index}>
                                    <div style={{ padding: '0.5vh' }} className="row align-items-center">
                                        {/*div img*/}
                                        <div className="col-2"  >
                                            <img className="img-fluid" style={{ height: '4.5em', width: '4em' }} src={process.env.REACT_APP_URL_IMAGES + img} />
                                        </div>
                                        {/*div cant*/}
                                        <div className="col-1"  >
                                            <h6>{item.cantidad}</h6>
                                        </div>
                                        {/*div titulo*/}
                                        <div className="col-6"  >
                                            <h6 style={{ fontSize: '0.8em' }}>{item.nombre}</h6>
                                        </div>
                                        {/*div precio*/}
                                        <div className="col-3" >
                                            <h6>${precio_format}</h6>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        <hr style={{ height: '2px', borderWidth: '0', color: 'gray', backgroundColor: 'gray' }}></hr>
                        <div className="row justify-content-around">
                            <div className="col-lg-6" >
                                <h6 style={{ textAlign: 'center' }}>Subtotal</h6>
                            </div>
                            <div className="col-lg-6" >
                                <h6 id="tv_subtotal" style={{ textAlign: 'center', color: 'green' }}>$ {totales.formatSubtotal}</h6>
                            </div>
                            <div className="col-lg-6" >
                                <h6 style={{ textAlign: 'center' }}>Envio</h6>
                            </div>
                            <div className="col-lg-6" >
                                <h6 id="tv_costo_envio" style={{ textAlign: 'center' }}>$ {totales.formatEnvio}</h6>
                            </div>
                            <div className="col-lg-6" >
                                <h6 style={{ textAlign: 'center' }}>Pago electrónico</h6>
                            </div>
                            <div className="col-lg-6" >
                                <h6 id="tv_costo_pago" style={{ textAlign: 'center' }}>$ {totales.formatMedioPago}</h6>
                            </div>
                            <br />
                            <div className="col-lg-12" >
                                <hr style={{ height: '2px', borderWidth: '0', color: 'gray', backgroundColor: 'gray' }}></hr>
                            </div>
                            <div className="col-lg-6" >
                                <h5 style={{ textAlign: 'center' }}>Total</h5>
                            </div>
                            <div className="col-lg-6" >
                                <h5 id="tv_costo_total" style={{ textAlign: 'center', color: 'green' }}>$ {totales.formatTotalConMedioPago}</h5>
                            </div>
                        </div>
                    </div>
                    <div style={{ textAlign: 'center' }} className="container">
                        <div className='row'>
                            <br /><br />
                            <div className="col-12" id="div_pago">
                                <br />
                                <div className={params.thisWidth > 400 ? 'container card' : ''}>
                                    <div className="col-lg-12" >
                                        <img style={{ height: '4em', width: '8em' }} src={process.env.REACT_APP_URL_IMAGES + imgPago} />
                                    </div>
                                    <br />
                                    <button onClick={validarModoPago} id="btnPago" style={{ backgroundColor: params.infoToPay.formaPago == 'contraentrega' ? 'green' : '#094293', color: 'white' }} className="btn btn-outline-success my-2 my-sm-0" type="button">
                                        {params.infoToPay.formaPago == 'contraentrega' ? 'Confirmar pago contraentrega' : 'Ir a pagos wompi'}
                                    </button>
                                    <button id='btnPagarLoading' style={{ display: 'none', backgroundColor: 'gray' }} className="btn btn-primary" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Loading...
                                    </button>
                                </div>
                                <br />
                            </div>
                        </div>
                        {/*formu wompi*/}
                        <form id='formWompi' action="https://checkout.wompi.co/p/" method="GET">
                            <input type="hidden" name="public-key" value="pub_prod_mm5Qq0EJtZhzNzjV4Vm6fLQx6aHhCbjS" />
                            <input type="hidden" name="currency" value="COP" />
                            <input type="hidden" name="amount-in-cents" value={totales.totalConMedioDePago + "00"} />
                            <input type="hidden" id='inputRefWompi' name="reference" value="" />
                            <input type="hidden" name="redirect-url" value={process.env.REACT_APP_URL_SERVS + "/redirectWompi.php"} />
                            <input type="hidden" name="shipping-address:address-line-1" value='Calle 100 20-30' />
                            <input type="hidden" name="shipping-address:country" value="CO" />
                            <input type="hidden" name="shipping-address:phone-number" value={datosCliente[0].cedula} />
                            <input type="hidden" name="shipping-address:city" value={datosCliente[0].correo} />
                            <input type="hidden" name="shipping-address:region" value='Santander' />
                        </form>
                        <br />
                        <div onClick={goWhats} className="container">
                            <div className="row justify-content-center">
                                <div className="col-5">
                                    <a className="btn btn-primary">Dudas? Preguntanos!</a>
                                </div>
                                <div className="col-5">
                                    <a style={{ cursor: 'pointer' }} ><img style={{ height: '3em', width: '3em' }} src={process.env.REACT_APP_URL_IMAGES + '/Imagenes_config/whatsapp1.png'} /></a>
                                </div>
                            </div>
                        </div>
                        <br /><br />
                        <button id='btnPagarValidarPago' style={{ display: 'none', backgroundColor: 'gray' }} className="btn btn-primary" type="button" disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span id='spanValidarPago'>Validando pago en ...</span>
                        </button>
                        <br />
                    </div>
                </div>
                {/*dialogo error en el pago*/}
                <button type="button" id='botonDialogoEliminar' data-bs-toggle="modal" data-bs-target="#dialogoEliminar" style={{ display: 'none' }}></button>
                <DialogoEliminar display={'none'} accion={processarIrcarrito} titulo={'Tu pago no ha salido bien!!'} textoConfirmar={'Intentar con otro modo de pago'} />
            </div>
        )
    } else {
        return (
            <div>
                <Cargando />
            </div>
        )
    }

}

export default CheckOut