import React from 'react'
import { useState, useEffect } from 'react';
import SuggestedProducts from './UIGeneral/SuggestedProducts'
import PojoProducto from '../services/PojoProductos'
import Cargando from './UIGeneral/Cargando';

const Searched = (params) => {
  const [palabra, setPalabra] = useState(params.nombre)
  const [urlSearch, setUrlSearch] = useState(params.url+"get_productos.php?code="+params.nombre)
  const [products, setProducts]= useState([])
  const [idProducto, setIdProducto] = useState('')

  //Guardo el parametro nombre en palabra para llegue un nuevo parametro recargar la pagina
  if(palabra!=params.nombre){
    let array=[]
    setProducts(array)
    setPalabra(params.nombre)
    setUrlSearch(params.url+"get_productos.php?code="+params.nombre)
  }

  function sendClickProducto(event){
    let id=event.currentTarget.id;
    setIdProducto(id)
    // darle un tiempo para renderizar el input con el valor id
    setTimeout(goAppjs, 100)
  }

  function goAppjs(){
    let div=document.getElementById('inputIdProduct');
    if(div!=null){
      div.click()
    } 
  }

  useEffect(() => {
    buscarProductos()
  }, [urlSearch])

  function buscarProductos(){
    if(isNaN(params.nombre)){
      fetch(urlSearch)
      .then((response) => {
        return response.json()
      }).then((json) => {
        if(json.length===0){
          document.getElementById('noencontrado').style.display=''
          document.getElementById('divCargando').style.display='none'
        }else{
          functionSetProductosSugeridos(json)
        } 
      })
    }else{
      setIdProducto(params.nombre)
      setTimeout(goAppjs, 100)
    }   
  }

  function functionSetProductosSugeridos(productos){
    if(products.length==0){
      let array=[]
      for(let x=0;x<productos.length;x++){
          let pojo = new PojoProducto(productos[x].nombre, productos[x].codigo)
          pojo.setListaImagenes(productos[x].listaImagenes)
          pojo.setImagen(pojo.listaImagenes[0].nombre)
          // darle formato al precio
          let precio_format=new Intl.NumberFormat("de-DE").format(productos[x].precio)
          pojo.setPrecio("$ "+precio_format)
          pojo.setRef(productos[x].referencia)
          array.push(pojo)
      }
      setProducts(array)
    }
  }

  if(products.length>0){
    return (
      <div className='container textAlignCenter'>
        <input type='hidden' name='valor' id='inputIdProduct' value={idProducto} onClick={params.getClickProduct}/>  
         <SuggestedProducts clickSuggested={sendClickProducto} categoria={'Resultados encontrados para '+palabra} productos={products}/> 
      </div>
    )
  }else{
    return (
      <div>
        <input type='hidden' name='valor' id='inputIdProduct' value={idProducto} onClick={params.getClickProduct}/> 
        <p style={{display: 'none', textAlign: 'center'}} id='noencontrado'>Lo siento no hemos encontrado nada. Intenta buscar con otras palabras...</p>
        <div style={{display: ''}} id='divCargando'>
          <Cargando/>
        </div>
      </div> 
      )
  } 
}

export default Searched