import React from 'react'
import GlobalFunctions from '../services/GlobalFunctions'
import { useState, useEffect } from 'react';
import DialogoEliminar from './UIShoppingCart/DialogoEliminar';
import DialogoNoCiudad from './UIShoppingCart/DialogoNoCiudad';
import PojoProducto from '../services/PojoProductos'
import SuggestedProducts from './UIGeneral/SuggestedProducts'
import FetchService from '../services/FetchService'

const ShoppingCart = (params) => {
    const fetchService= new FetchService()
    const glob= new GlobalFunctions()
    const [products, setProducts]= useState([])
    const [displayLoading, setDisplayLoading]= useState('')
    const [displayVacio, setDisplayVacio]= useState('none')
    const [itemEliminar, setItemEliminar]= useState({
      id: '',
      index: ''
    })
    const [totales, setTotales]= useState({
      subtotal: 0,
      formatSubtotal: 0,
      envio: 0,
      formatEnvio: 0,
      totalConEnvio: 0,
      formatTotalConEnvio: 0,
      medioPago: 0,
      formatMedioPago: 0,
      totalConMedioDePago: 0,
      formatTotalConMedioPago: 0
    })
    const [badgeCarrito, setBadgeCarrito]= useState(0)
    const [infoCliente, setInfoCliente]= useState([])
    const [infoPago, setInfoPago]= useState([])
    const [modoPago, setModoPago]= useState('contraentrega')
    const [productoSugeridos, setProductosSugeridos]= useState([])
    const [idProducto, setIdProducto] = useState('')
    const [btnDialogoCiudad, setBtnDialogoCiudad] = useState('inline')

    useEffect(() => {
      validarUsuario()
      setTimeout(() => {
        if(document.getElementById('inputBadgeCarrito')!=null){
          document.getElementById('inputBadgeCarrito').click()
        }
      }, 1000);
    }, [products, displayLoading, displayVacio, totales])

    useEffect(()=>{
      if(infoCliente.length>0){
        calcularTotales()
      }
    }, [infoCliente, modoPago])

  function validarUsuario(){
        setTimeout(()=>{
          if(glob.getCookie('correo')==null || glob.getCookie('correo')==''){
            if(document.getElementById('inputGoLogin')!=null){
              document.getElementById('inputGoLogin').click()
            }
          }else{
            fetchGetProducts()
          } 
        },50) 
  }

    function abrirDialogoElimininar(id, index){
      setItemEliminar({
        id: id,
        index: index
      })
      document.getElementById('botonDialogoEliminar').click()
    }

    function borrar(){
      document.getElementById('botonDialogoEliminar').click()
      const url = glob.URL_SERV+'getCarrito.php?modo=borrarCarrito&id='+itemEliminar.id+"&cliente="+glob.getCookie('correo')
        fetch(url)
        .then((response) => {
          return response.json()
        }).then((json) => { 
          document.getElementById(itemEliminar.index).style.display='none'
          recargarCarrito()
        })
    }

    function menosCant(id, cant, index){
      if(cant>1){
        document.getElementById(index).style.display=''
        const cant1 = parseInt(cant)-1
        actualizarCarrito(id, cant1, index)
      }
    }
    
    function masCant(id, cant, index){
      if(cant<6){
        document.getElementById(index).style.display=''
        const cant1 = parseInt(cant)+1
        actualizarCarrito(id, cant1, index)
      }
    }

    function actualizarCarrito(id, cant, index){
      let objeto = {
        "cod": id,
        "cliente": glob.getCookie('correo'),
        "cantidad": cant
      };
      const url = glob.URL_SERV+'getCarrito.php?modo=actualizarCantidad'
        fetch(url, {
          method: 'POST',
          body: JSON.stringify(objeto),
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then((response) => {
          return response.json()
        }).then((json) => { 
          document.getElementById(index).style.display='none'
          recargarCarrito()
        })
    }
   
  function recargarCarrito(){
    const array=[]
    setProducts(array)
    setInfoCliente(array)
  }  

   function fetchGetProducts(){
      setBadgeCarrito(products.length)
      const exp= 3600*24*365
      glob.setCookie('badgeCarrito', products.length, exp)
      if(products.length==0){
        const url = glob.URL_SERV+'getCarrito.php?modo=getCarrito&id='+glob.getCookie('correo')
        fetch(url)
        .then((response) => {
          return response.json()
        }).then((json) => {
            if(json[0].nombre!='0'){
              setProducts(json)
              fetchService.getDatosCliente().then(datos=>setInfoCliente(datos))
            }else{
              /// si carrito esta vacio.....
              setDisplayLoading('none')
              setDisplayVacio('')
              functionSetProductosSugeridos()
            }
        })
    }
  }
  
  function calcularTotales(){
    // ciudad envio llega vacio!!!
    if(infoCliente[0].usuario!='' || infoCliente[0].usuario != null){
      let sub=0
      products.map((item)=>{
         sub=sub+parseInt(item.precio)*item.cantidad
      })
      // subtotal
      let formatSub= new Intl.NumberFormat("de-DE").format(sub)
      // envio
      if(infoCliente[0].ciudad != null){
        let formatEnvio= new Intl.NumberFormat("de-DE").format(totalizarCostoEnvio(sub))
        let formatTotalConEnvio= new Intl.NumberFormat("de-DE").format(parseFloat(totalizarCostoEnvio(sub))+sub)
        // modo de pago
        let formatMedioPago= new Intl.NumberFormat("de-DE").format(Math.round(totalizarModoDepago(sub)))
        // suma de subtotal+ envio + medio de pago
        let totalEnvioMasMedioPago=Math.round(parseFloat(sub)+parseFloat(totalizarModoDepago(sub))+parseFloat(totalizarCostoEnvio(sub))) 
        let formatTotalConMedioDePago= new Intl.NumberFormat("de-DE").format(totalEnvioMasMedioPago) 
        setTotales({
          ...totales,
          subtotal: sub,
          formatSubtotal: formatSub,
          envio: totalizarCostoEnvio(sub),
          formatEnvio: formatEnvio,
          totalConEnvio: parseInt(totalizarCostoEnvio(sub))+parseInt(sub),
          formatTotalConEnvio: formatTotalConEnvio,
          medioPago: totalizarModoDepago(sub),
          formatMedioPago: formatMedioPago,
          totalConMedioDePago: totalEnvioMasMedioPago,
          formatTotalConMedioPago: formatTotalConMedioDePago
        })
      }else{
        document.getElementById('botonDialogoCiudad').click()
      }
    }
  }

  function cambiarModoPago(metodo){
    if(metodo=='electronico'){
      setModoPago(metodo)
    }else{
      setModoPago('contraentrega')
    }
  }

  function totalizarModoDepago(subtotal){
    setCheckedRadioPagos()
    let costoModoPago=0;
    if(modoPago=='contraentrega'){
       // referencia es pago contraentrega y cantidad costo pasarela de pagos...
      costoModoPago=parseFloat(params.lista[0].referencia)*parseFloat(subtotal)
    }else{
      costoModoPago=parseFloat(params.lista[0].cantidad)*parseFloat(subtotal)
    }
    return costoModoPago;
  }

  function setCheckedRadioPagos(){
    if(modoPago=='contraentrega'){
      document.getElementById('contraentrega').checked = true
      document.getElementById('wompi').checked = false
    }else{
      document.getElementById('wompi').checked = true
      document.getElementById('contraentrega').checked = false
    }
  }

  function totalizarCostoEnvio(subto){
    let envio=0;
    let ciudadEnvio=infoCliente[0].ciudad;
    if(ciudadEnvio.toUpperCase()=='BUCARAMANGA' || ciudadEnvio.toUpperCase()=='GIRON' || ciudadEnvio.toUpperCase()=='FLORIDABLANCA'){
      if(subto>100000){
        envio=0
      }else{
        envio=params.lista[0].imagen
      }
    }else{
      envio=params.lista[0].precio
    }
    return envio
  }

  function goNovedades(){
    setTimeout(() => {
      document.getElementById('Novedades').click()
    }, 100);
  }

  function goWhats(){
    let href="https://api.whatsapp.com/send?phone=057"+params.lista[0].listaImagenes[0]+"&text=";
    window.open(href, "nuevo", "directories=no, location=no, menubar=no, scrollbars=yes, statusbar=no, tittlebar=no, width=800, height=600");
  }

  function goHome(){
    document.getElementById('inputRegresarInicio').click()
  }

  function modificarCiudad(){
    // IGUAL TOCA HCERLO PARA DESPUES DE IR A PAGAR....
    const exp= 3600*24*365
    glob.setCookie('comeBackCarrito', true, exp)
    document.getElementById('botonDialogoCiudad').click()
    document.getElementById('inputGoProfile').click()
  }

  function cargarInfoToPay(){
    if(infoCliente[0].ciudad != null){
      // cookie para saber que hay parametros y cargar checkout. de los contrario redireccionar....
      const exp= 3600*24*365
      glob.setCookie('paramsCheckOut', 'true', exp)
      document.getElementById('btnPagar').style.display='none'
      document.getElementById('btnPagarLoading').style.display='inline'
      infoPago.productos=products
      infoPago.totales=totales
      infoPago.formaPago=modoPago
      setInfoPago(infoPago)
      document.getElementById('inputInfoToPay').click()
    }else{
      setBtnDialogoCiudad('none')
      setTimeout(() => {
        document.getElementById('botonDialogoCiudad').click()
      }, 100);
    } 
  }

  function numeroAleatorio(){
    return parseInt(Math.random() * (params.productos.length - 0) + 0)
  }

  function functionSetProductosSugeridos(){
    if(productoSugeridos.length==0){
      // Creación de arrays para mostrar resumen de productos por categorias
    let array=[]
    let num1= numeroAleatorio()
    let num2=num1+12  
    if(num2>params.productos.length){
      num1=0
    } 
    for(let x=num1;x<num1+12;x++){
        let pojo = new PojoProducto(params.productos[x].nombre, params.productos[x].codigo)
        pojo.setListaImagenes(params.productos[x].listaImagenes)
        pojo.setImagen(pojo.listaImagenes[0].nombre)
        // darle formato al precio
        let precio_format=new Intl.NumberFormat("de-DE").format(params.productos[x].precio)
        pojo.setPrecio("$ "+precio_format)
        pojo.setRef(params.productos[x].referencia)
        array.push(pojo)
    }
      let array1=array.sort(() => Math.random() - 0.5)
      let array2=[]
      let nums=0
      if(array1.length<=9){
          nums=array1.length
      }else{
        nums=9
      }
      for(let r=0;r<nums;r++){
        array2.push(array1[r])
      }
      setProductosSugeridos(array2)
    }
  }

  function sendClickProducto(event){
    setIdProducto(event.currentTarget.id)
    setTimeout(() => {
      document.getElementById('inputSuggestedProduct').click()
    }, 100);
  }

  if(products.length>0){
    return (
      <div className='container'>
        <input type='hidden' id='inputBadgeCarrito' onClick={params.badgeCarrito} value={badgeCarrito} />
        <input type='hidden' id='inputGoLogin' onClick={params.ir_login} value={'pedirRegistro'}/>
        <input type='hidden' id='inputGoProfile' onClick={params.goProfile} />
        <div className="row" style={{color: 'green',}}>
          <div className="align-self-center">
            <h5 style={{textAlign: 'center'}} >Carrito de compras</h5>
          </div>
        </div>
        <div className='container'>
          <div className="row">
    
            <div className="col-lg-9 col-md-9 col-sm-12 col-12 border border-success">
                  {products.map((item, index)=>{
                    const img='./Imagenes_productos/'+item.imagen;
                    const precio=parseInt(item.precio)*item.cantidad
                    let precio_format=new Intl.NumberFormat("de-DE").format(precio)
                    return(
                      <div key={index} >
                        
                        <div style={{padding: '0.5vh'}} className="row align-items-center">
                            {/*div img*/}
                            <div className="col-2"  >
                              <img className="img-fluid" style={{height:'4.5em', width:'4em'}} src={process.env.REACT_APP_URL_IMAGES+img}/> 
                            </div>
                            {/*div titulo*/}
                            <div className="col-7"  >
                              <h6 style={{fontSize:'0.8em'}}>{item.nombre}</h6> 
                            </div> 
                            {/*div precio*/}
                            <div className="col-3" >
                              <h6>${precio_format}</h6> 
                            </div>
                        </div> 

                        <div style={{padding: '0.5vh'}} className='row justify-content-between'>
                              <div style={{marginLeft: '1em'}} className='col-2'>
                                <button id={index} style={{display:'none', backgroundColor:'gray'}} className="btn btn-primary btn-sm" type="button" disabled>
                                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>
                              </div>
                              <div className='col-4'>
                                {/*div cant*/}
                                <div style={{alignItems: 'center'}} className="row">
                                  <div style={{marginBottom: '0.1em'}} onClick={() => menosCant(item.codigo, item.cantidad, index)}  className="col-lg-4 col-md-4 col-sm-4 col-4 cursorPointer">
                                    <button type='button' className="btn btn-light btn-sm"><i className="fas fa-minus fa-sm"></i></button>
                                  </div>
                                  <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                                    <h5 style={{color:'green', marginLeft:'0.5em'}}>{item.cantidad}</h5>
                                  </div>
                                  <div style={{ marginBottom: '0.1em'}} onClick={() => masCant(item.codigo, item.cantidad, index)} className="col-lg-4 col-md-4 col-sm-4 col-4 cursorPointer">
                                    <button type='button' className="btn btn-light btn-sm"><i  className="fas fa-plus fa-sm"></i></button>
                                  </div>
                                </div>
                              </div>
                              <div onClick={() => abrirDialogoElimininar(item.codigo, index)} className='col-2'>
                                <button className='btn btn-danger btn-sm'><img style={{height:'1em', width:'1em', cursor: 'pointer'}} src={process.env.REACT_APP_URL_IMAGES+'Imagenes_config/trash.png'}/></button>
                              </div>
                        </div>
                        <hr style={{height: '2px', borderWidth: '0', color: 'gray', backgroundColor: 'gray'}}></hr>  
                      </div>
                    )
                  })}
            </div>
            
            <div  style={{textAlign: 'center'}} className="col-lg-3 col-md-3 col-sm-12 col-12 border border-success">
            <br/>
              <h6 style={{textAlign: 'center'}}>Subtotal</h6>
              <h6 style={{color: 'green', textAlign: 'center'}}>$ {totales.formatSubtotal}</h6>
              <hr style={{height: '2px', borderWidth: '0', color: 'gray', backgroundColor: 'gray'}}></hr>
              <h6>Costo envio</h6>
              <p style={{fontSize: '0.8em'}}>(Envio gratis en el AM de Bucaramanga por compras superiores a $100.000)</p>
              <p style={{fontSize: '14px'}}>$ {totales.formatEnvio}</p>
              <h6 style={{texAlign: 'center'}}>Total con envio</h6>
              <h6 style={{color: 'green', textAlign: 'center'}}>$ {totales.formatTotalConEnvio}</h6>

              <hr style={{height: '2px', borderWidth: '0', color: 'gray', backgroundColor: 'gray'}}></hr>

              <h6>Medio de pago</h6>
              <div onClick={()=>cambiarModoPago('contraentrega')} style={{padding: '1vh'}} className="container card" > 
                <div className="row align-items-center ">
                <label  style={{textAlign: 'center'}}>Contraentrega</label>
                  <div className="col-4">
                    <input type="radio"  id="contraentrega" name="medio_pago" value="contraentrega"/>
                  </div>
                  <div className="col-8">
                    <img  style={{height:'7vh', width:'9vh'}}  src={process.env.REACT_APP_URL_IMAGES+'Imagenes_config/img_pago_contra_entrega.png'} />
                  </div>
                </div>
              </div>
              
              <div onClick={()=>cambiarModoPago('electronico')} style={{padding: '1vh'}} className="container card" > 
                  <div className="row align-items-center">
                  <label  style={{textAlign: 'center'}}>Pago en linea</label>
                    <div className="col-4">
                      <input type="radio" id="wompi" name="medio_pago" value="wompi"/>
                    </div>
                    <div className="col-8">
                      <img style={{height:'7vh', width:'10vh'}} src={process.env.REACT_APP_URL_IMAGES+'Imagenes_config/wompi_btn.png'} />	
                    </div>
                  </div>
              </div>
              <br/>
              <h6 style={{textAlign: 'center'}}>Costo medio de pago</h6>
              <h6 style={{color: 'green', textAlign: 'center'}}>$ {totales.formatMedioPago}</h6>
              <hr style={{height: '2px', borderWidth: '0', color: 'gray', backgroundColor: 'gray'}}></hr>

              <h5 style={{textAlign: 'center'}}>Total a pagar</h5>
              <h5 style={{color: 'green', textAlign: 'center'}}>$ {totales.formatTotalConMedioPago}</h5>

              <input type="hidden"  id='inputInfoToPay'	onClick={() => params.goCheckOut(infoPago)} name="medio_pago"/>
              <button type='button' id='btnPagar' onClick={cargarInfoToPay} style={{fonSize: '18px', backgroundColor: 'green', textAlign: 'center'}} className="btn btn-primary">Ir a pagar</button>
              <button id='btnPagarLoading' style={{display:'none', backgroundColor:'green'}} className="btn btn-primary" type="button" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Loading...
              </button>
              <br/><br/>
            </div>
            
          </div>
          <br/>
        </div> 

        <div className="container">
          <div className="row justify-content-between">
                <div onClick={goHome} className="col-3">
                   <input type='hidden' id='inputRegresarInicio' onClick={params.clickRegresarInicio} />
                   <a  style={{fontSize: '1em', backgroundColor: '#f0e094', color: 'green', width: '14em' }} className="card btn btn-primary"><strong>Continuar comprando</strong></a> 
                </div>
                <div onClick={goWhats} style={{cursor: 'pointer'}} className="col-3">
                  <strong>Dudas? </strong>
                  <img style={{width: '2em'}} src={process.env.REACT_APP_URL_IMAGES+'Imagenes_config/whatsapp1.png'}/>
                </div>  
           </div>  
          <br/>
          <p style={{fontSize: '0.8em'}}><strong style={{color: 'red'}}>*</strong>La disponibilidad, el precio y la cantidad de unidades de los productos esta sujeta a las unidades disponibles en inventario. 
          {params.lista[0].nombre} no se hace responsable por el posible agotamiento de unidades.</p>
        </div>          
        <br/>
        {/* dialogo eliminar*/}
        <button  type="button" id='botonDialogoEliminar' data-bs-toggle="modal"  data-bs-target="#dialogoEliminar" style={{display:'none'}}></button>
        <DialogoEliminar display={'inline'} accion={borrar} titulo={'Eliminar este producto'} textoConfirmar={'Confirmar'}/>
        <button  type="button" id='botonDialogoCiudad' data-bs-toggle="modal"  data-bs-target="#dialogoCiudad" style={{display:'none'}}></button>
        <DialogoNoCiudad titulo={'No se ha encontrado la ciudad de envío!'} btnCancel={btnDialogoCiudad} modificarCiudad={modificarCiudad}/>
      </div>
      )
  } else{
    return(
      <div style={{textAlign: 'center', marginTop: '4em'}}>
        <input type='hidden' id='inputBadgeCarrito' onClick={params.badgeCarrito} value={badgeCarrito} />
        <input type='hidden' id='inputGoLogin' onClick={params.ir_login} value={'Debes identificarte para ver tu carrito!'}/>
        <img style={{display: displayLoading}} src={process.env.REACT_APP_URL_IMAGES+'Imagenes_config/loading.gif'} width="100" height="100" />
        <br/>
        <div style={{display: displayVacio, padding: '1em'}}>
          <h5>Tu carrito esta vacío!</h5>
          <input type='hidden' id='Novedades' onClick={params.searchNovedades}/>
          <button type="button" onClick={goNovedades} className="btn btn-outline-success">Presiona aquí para ver muchas super novedades!!!</button>
        </div>
        <br/> <br/> <br/> <br/>
        <input type='hidden' id='inputSuggestedProduct' onClick={params.getClickProduct} value={idProducto}/>
        <div style={{display: displayVacio}}>
          <SuggestedProducts clickSuggested={sendClickProducto} categoria='Prueba estos productos en tu hogar!' productos={productoSugeridos}/>
        </div>
      </div>
    )
  }
}

export default ShoppingCart