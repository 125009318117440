import React from 'react'

const DialogoNoCiudad = (params) => {

  return (
    <div className="modal fade" id="dialogoCiudad" tabIndex="-1" aria-labelledby="exampleModalLabel"   aria-hidden="true">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">{params.titulo}!</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
        </div>
            <div className="modal-footer">
              <button type="button" style={{display: params.btnCancel}} className="btn btn-secondary" data-bs-dismiss="modal" >Continuar sin cotizar envío</button>
              <button type="button" onClick={params.modificarCiudad}  className="btn btn-danger">Modificar ciudad</button>
            </div>

      </div>
    </div>
  </div>
  )
}

export default DialogoNoCiudad