import React from 'react'
import { useState, useEffect } from 'react';
import GlobalFunctions from '../services/GlobalFunctions'
import FetchService from '../services/FetchService'


const CrearCuenta = (params) => {
  const glob= new GlobalFunctions()
  const fetchService= new FetchService()
  const [usuario, setUsuario] = useState('')
  const [email, setEmail] = useState('')
  const [email1, setEmail1] = useState('')
  const [contraseña, setContraseña] = useState('')
  const [contraseña1, setContraseña1] = useState('')
  const [notification, setNotificacion] = useState('')
  const [actualizarDatosUsuario, setActualizarDatosUsuario] = useState(false)
  const [cargarDatosUsuario, setCargarDatosUsuario] = useState(true)

  useEffect(()=>{
    if(params.mensajeNoCorreo!==notification){
       setNotificacion(params.mensajeNoCorreo)
    }    
  },[])

  useEffect(()=>{
    if(notification=='No tienes un correo registrado!'){
      setActualizarDatosUsuario(true)
      if(cargarDatosUsuario){
         if(document.getElementById('imgLoading')!=null){
           document.getElementById('imgLoading').style.display=''
         } 
        fetchDatosUsuario()
      }  
   }    
  })

  function fetchDatosUsuario(){
    fetchService.getDatosUsuarioByCedula().then(datos=>{
      setCargarDatosUsuario(false)
      setUsuario(datos.usuario)
      setEmail(datos.correo)
      setEmail1(datos.correo)
      setContraseña(datos.clave)
      setContraseña1(datos.clave)
      document.getElementById('imgLoading').style.display='none'
    })
  }

  function establecerUsuario(e){
    setUsuario(e.target.value)
  }

  function establecerEmail(e){
    setEmail(e.target.value)
  }

  function establecerEmail1(e){
    setEmail1(e.target.value)
  }

  function establecerContraseña(e){
    setContraseña(e.target.value)
  }

function establecerContraseña1(e){
    setContraseña1(e.target.value)
}

function validarDatos(e){
  document.getElementById('imgLoading').style.display=''
    e.preventDefault()
    if(email!=email1){
      setNotificacion('Debes ingresar el mismo email!')
      document.getElementById('imgLoading').style.display='none'
    }else{
      if(contraseña!=contraseña1){
        setNotificacion('Debes ingresar la misma contraseña!')
        document.getElementById('imgLoading').style.display='none'
      }else{
        setNotificacion('')
        fetchValidarCorreo()
      }
    }
}

function fetchValidarCorreo(){
  let url=glob.URL_SERV+"get_clientes.php?modo=correo&email="+email+"&usuario="+usuario
    fetch(url)
      .then(function(response) {
        return response.json()
      }).then(function(data) {
        validarCorreo(data)
      }).catch(function(error) {
        document.getElementById('imgLoading').style.display='none'
      })
}

function validarCorreo(datos){
  if(datos[0].correo=='No existe'){
      if(actualizarDatosUsuario){
        fetchActualizarDatosUsuario()
      }else{
         enviarFormulario()
      }
  }else{
    setNotificacion('Ya existe un usuario registrado con este email!')
    document.getElementById('imgLoading').style.display='none'
  }
}

function fetchActualizarDatosUsuario(){
  let objeto = {
    "usuario": usuario,
    "correo": email,
    "clave": contraseña
  }
  const URL_SERV = process.env.REACT_APP_URL_SERVS+'get_clientes.php?modo=actualizarDatosUsuarioByCedula&cedula='+glob.getCookie('cedula');
  fetch(URL_SERV, {
      method: 'POST',
      body: JSON.stringify(objeto),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(function(response) {
      return response.text();
    }).then(function(data) {
      console.log(data)
       if(data!=""){
         document.getElementById('imgLoading').style.display='none'
         setNotificacion('Usuario actualizado')
         const exp= 3600*24*365
         glob.setCookie('correo', email, exp) 
         glob.setCookie('usuario', usuario, exp)       
         glob.setCookie('clave', contraseña, exp)
         document.getElementById('inputGoProfile').click()
       }else{
         setNotificacion('Error al actualizar usuario!')
       }
    }).catch(function(error) {
       document.getElementById('imgLoading').style.display='none'
    })
}

function enviarFormulario(){
    const fecha= glob.getFecha()
    let objeto = {
      "usuario": usuario,
      "email": email,
      "clave": contraseña,
      "fecha": fecha
    };
    const URL_SERV = process.env.REACT_APP_URL_SERVS+'get_clientes.php?modo=registrarCliente';
    fetch(URL_SERV, {
        method: 'POST',
        body: JSON.stringify(objeto),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(function(response) {
        return response.text();
      }).then(function(data) {
        validarRegistro(data)
      }).catch(function(error) {
        document.getElementById('imgLoading').style.display='none'
      })
}

  function validarRegistro(data){
    if(data=="No registro"){
      alert('Ha ocurrido un error! Cuéntanoslo por alguno de nuestros medios de atención.')
      document.getElementById('imgLoading').style.display='none'
    }else{
      document.getElementById('imgLoading').style.display='none'
      const exp= 3600*24*365
      glob.setCookie('usuario', usuario, exp)
      glob.setCookie('correo', email, exp)
      document.getElementById('inputRegresarInicio').click()
      // si viene de dar comprar a un producto, redireccionar....
            if(glob.getCookie('productForCar')!=''){
                document.getElementById('inputHiddenRedirectProduct').click()
                glob.setCookie('productForCar', '', 0)
            }
    }
  }

  return (
  <div className="container">
      <input type='hidden' id='inputHiddenRedirectProduct' onClick={params.goProduct} />
      <input type='hidden' id='inputGoProfile' onClick={params.goProfile} />
      <br/>
      <h4 id="tv_titulo" style={{color: 'black', textAlign: 'center'}}>{actualizarDatosUsuario ? 'Actualizar usuario' : 'Registrarse'}</h4>
      <hr/> 
    <div className='row'>
      <br/> 
      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
          <form  action="Registrar_cliente_casa_bonita_web.php" onSubmit={validarDatos} method="post"> 
                <p style={{textAlign: 'justify', color: 'black'}}>Nombre de usuario</p>
                <input type="text" name="nombre"  className="form-control" onChange={establecerUsuario} required value={usuario}/> 
            <br/>
            <p style={{textAlign: 'justify', color: 'black'}}>Correo electrónico</p>
                  <input type="text"  name="mail" required onChange={establecerEmail}  className="form-control" value={email}/> 
                  <br/>
            <p style={{textAlign: 'justify', color: 'black'}}>Repite el correo electrónico</p>
                  <input type="text"  name="mail2" required onChange={establecerEmail1} className="form-control" value={email1}/> 
                  <br/>
            <p style={{textAlign: 'justify', color: 'black'}}>Contraseña</p>
                  <input type="password"  name="contraseña" required  className="form-control" onChange={establecerContraseña} value={contraseña}/> 
                  <br/>
            <p style={{textAlign: 'justify', color: 'black'}}>Repite la contraseña</p>
                  <input type="password"  name="contraseña2" required  onChange={establecerContraseña1} className="form-control" value={contraseña1}/> 
                  <br/>
                  <input type='hidden' id='inputRegresarInicio' onClick={params.clickRegresarInicio} />      
            <p style={{textAlign: 'justify', color: 'red'}}>{notification}</p>
            <div style={{textAlign: 'center'}} className="container">
            <button className="btn btn-outline-success my-2 my-sm-0" type="submit" >{actualizarDatosUsuario ? 'Actualizar usuario' : 'Registrarse'}</button>
            <br/><br/>
            </div>
          </form>	
      </div>
          <br/>
    

        <div style={{textAlign: 'center'}} className="col-xs-12 col-sm-6 col-md-6 col-lg-6"> 
          <img id='imgLoading' style={{display:'none'}} src={process.env.REACT_APP_URL_IMAGES+'/Imagenes_config/loading.gif'} width="80" height="80"></img>
          <br/>
          <img width="300" height="300" src={process.env.REACT_APP_URL_IMAGES+'Imagenes_config/la_humildad.png'} style={{margin: 'auto', display: 'block'}} alt="Placeholder image" />
          <br/> 
        </div>
        <br/>
      </div>  
  </div>
  )
}

export default CrearCuenta